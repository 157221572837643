import { AxiosRequestConfig } from 'axios'
import { IAvatarUploaded, IUserModel, IVideoModel, TAxiosResponseData } from 'src/interfaces'
import { ICompanyModel } from 'src/interfaces/models/company.model'
import { axiosHiringApi } from './axios'

export class ProfileApi {
  static readonly _prefix = '/profile'

  /**
   * Get authenticated profile
   */
  static detail(): TAxiosResponseData<IUserModel> {
    return axiosHiringApi.get(this._prefix)
  }

  static getProfile = ProfileApi.detail

  /**
   * Update profile
   */
  static update(
    payload: {
      firstName?: string
      lastName?: string
      userPhoneNo?: string
      linkedInUrl?: string
      userType?: string
      password?: string
      newPassword?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(this._prefix, payload, config)
  }

  /**
   * Update company profile
   */
  static updateCompany(
    payload: {
      logo?: File
      companyName?: string
      companyUrl?: string
      userType?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICompanyModel | void> {
    const formData = new FormData()
    for (const field of Object.keys(payload) as (keyof typeof payload)[]) {
      const value = payload[field]
      if (value) {
        formData.append(field, value)
      }
    }
    return axiosHiringApi.post(`${this._prefix}/company`, payload, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  /**
   * Update basic profile
   */
  static updateBasic(payload: {
    firstName?: string
    lastName?: string
    username?: string
    email?: string
  }): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/basic`, payload)
  }

  /**
   * Update public profile
   */
  static updatePublic(payload: {
    companyKey?: string
    companyName?: string
    companyUrls?: string[]
    scheduleUrl?: string
    bio?: string
  }): TAxiosResponseData<void> {
    return axiosHiringApi.put(`${this._prefix}/public`, payload)
  }

  /**
   * Upload avatar
   */
  static uploadAvatar(payload: { key: string } | FormData): TAxiosResponseData<{ pfp: IAvatarUploaded }> {
    return axiosHiringApi.post(`${this._prefix}/avatar`, payload)
  }

  /**
   * Upload video
   */
  static uploadVideo(payload: {
    key: string
    photoKey?: string
    transcription?: string
    thumbnailOffset?: number
  }): TAxiosResponseData<{ pfv: IVideoModel }> {
    return axiosHiringApi.post(`${this._prefix}/video`, payload)
  }

  /**
   * Upload video
   */
  static uploadOnboardingVideo(payload: {
    key: string
    photoKey?: string
    transcription?: string
    thumbnailOffset?: number
  }): TAxiosResponseData<{ pfv: IVideoModel }> {
    return axiosHiringApi.post(`${this._prefix}/onboarding-video`, payload)
  }

  /**
   * Remove video
   */
  static delete(): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/video`)
  }

  /**
   * Destroy Phil's account
   */
  static deletePhil(): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/phil`)
  }
}
