import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, CampaignClaimApi } from 'src/api'
import {
  BreadcrumbHeading,
  Button,
  Divider,
  Editor,
  GoogleLocationSearch,
  Input,
  Select
} from 'src/components'
import { DateTimePicker } from 'src/components/datepicker'
import { GroupRadio } from 'src/components/group-radio'
import { CAMPAIGN_LOCATION_TYPE_OPTIONS, CAMPAIGN_RESUME_TO_APPLY_OPTIONS, CAMPAIGN_SALARY_RATE_OPTIONS, CAMPAIGN_TRADE_OPTIONS } from 'src/constants'
import { ELocationType } from 'src/enums'
import { useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect, useValidation } from 'src/hooks'
import { ICampaignModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignCompanyVerify } from 'src/partials'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getApiErrorMessage, getFirstCompany } from 'src/utils'
import { object, string } from 'yup'
import { CompanyInfo } from '../components/company-info'
import { RecordVVC } from '../components/record-vvc'
import Style from './style.module.scss'

export const ClaimJobsBySlug: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()

  const { slug } = useParams<{ slug: string }>()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const isVerify = useMemo(() => getFirstCompany(profile)?.CompanyUser?.isVerified, [profile])

  const [stage, setStage] = useState<'info' | 'verify'>('info')
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<
    Partial<ICampaignModel> & {
      companyPhoneNo?: string
      linkedIn?: string
    }
  >({ requiredResume: true })

  useEffect(() => {
    const company = getFirstCompany(profile)
    const patch: Partial<Record<'companyPhoneNo' | 'linkedIn', string>> = {}
    if (company?.CompanyUser?.companyPhoneNo) {
      patch.companyPhoneNo = company.CompanyUser.companyPhoneNo
    }
    if (company?.CompanyUser?.linkedIn) {
      patch.linkedIn = company.CompanyUser.linkedIn
    }
    if (Object.keys(patch).length) {
      setFormData((prev) => ({
        ...prev,
        ...patch
      }))
    }
  }, [profile])

  const schema = useMemo(() => object().shape({
    companyPhoneNo: string().required().matches(/^([1-9]{1})([0-9]{9,10})$/, 'Invalid phone number').label('Phone')
  }), [])

  const { errors, validate } = useValidation({
    data: formData,
    schema
  })

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onSubmit = useCallback(async () => {
    if (!formData.id || !formData.uploadVideoId) return

    if (stage === 'info' && !isVerify) {
      setStage('verify')
      return
    }

    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    from(CampaignClaimApi.create({
      campaignId: formData.id,
      uploadVideoId: formData.uploadVideoId,
      requiredResume: formData.requiredResume,
      canTrade: formData.canTrade,
      specifyTrade: formData.specifyTrade,
      closedAt: formData.closedAt,
      companyPhoneNo: formData.companyPhoneNo,
      linkedIn: formData.linkedIn
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        history.push(generate(ERoutes.CAMPAIGN_MY_JOBS, { tab: isVerify ? 0 : 2 }))
      })
  }, [formData, isVerify, history, stage, unsubscribe$, validate])

  useUnsubscribeEffect(
    (unsubscribe$) => {
      setLoading(true)
      from(CampaignApi.publicCampaign(slug))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            SnackbarService.error(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          setFormData((prev) => ({
            ...prev,
            ...data,
            question: data.question || 'Besides money, what aspect of work motivates you?'
          }))
        })
    },
    [slug]
  )

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      {stage === 'info'
        ? (
          <div className="fx-1 fx-column gap-8 p-8 bg-neutral-20 w-100-p">
            <div className="fx-column gap-3">
              <div className="fs-28 fw-600 txt-black-01">Claim a Job</div>
              <div className="fs-16 fw-600 txt-grey-01">Add Job Details</div>
            </div>

            <div
              className="p-8 bg-neutral-white round-4 fx-column gap-10"
              style={{ maxWidth: 950 }}
            >
              <div className="fx fx-jc-space-between gap-10">
                <RecordVVC campaign={formData} onChange={setFormData}/>

                <div className="fx-column gap-6">
                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Job Title<span className="txt-red-02">*</span>
                    </div>
                    <Input
                      className="py-1"
                      name="jobTitle"
                      placeholder="Job title"
                      disabled
                      value={formData?.jobTitle}
                      style={{ width: 130 }}
                    />
                  </div>

                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Salary<span className="txt-red-02">*</span>
                    </div>
                    <div className="fx fx-ai-center gap-3">
                      <Input
                        className="py-1"
                        type="currency"
                        name="minSalary"
                        placeholder="Min"
                        disabled
                        value={(formData?.salaryValue as { min?: number; max?: number })?.min}
                        style={{ width: 130 }}
                      />

                      <div>-</div>

                      <Input
                        className="py-1"
                        type="currency"
                        name="maxSalary"
                        placeholder="Max"
                        disabled
                        value={(formData?.salaryValue as { min?: number; max?: number })?.max}
                        style={{ width: 130 }}
                      />

                      <Select
                        height={48}
                        className="ml-4"
                        value={formData.salaryRate}
                        options={CAMPAIGN_SALARY_RATE_OPTIONS}
                        isDisabled
                        style={{ width: 160 }}
                      />
                    </div>
                  </div>

                  <div className="fx gap-8">
                    <div className="fx-column gap-2" style={{ width: '30%' }}>
                      <div className="fw-700 txt-black-01 lh-20">
                        Workplace Type<span className="txt-red-02">*</span>
                      </div>
                      <Select
                        height={48}
                        value={formData.locationType}
                        options={CAMPAIGN_LOCATION_TYPE_OPTIONS}
                        isDisabled
                      />
                    </div>

                    {formData.locationType !== ELocationType.REMOTE && (
                      <div className="fx-column gap-2" style={{ width: '70%' }}>
                        <div className="fw-700 txt-black-01 lh-20">
                          Location<span className="txt-red-02">*</span>
                        </div>
                        <GoogleLocationSearch
                          disabled
                          location={formData.location}
                        />
                      </div>
                    )}
                  </div>

                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Expiration Date<span className="txt-red-02">*</span>
                    </div>
                    <DateTimePicker
                      placeholder="mm/dd/yyyy"
                      disabled={loading}
                      minDate={new Date()}
                      value={formData?.closedAt ? new Date(formData?.closedAt) : undefined}
                      onChange={closedAt => setFormData((prev) => ({ ...prev, closedAt }))}
                      error={errors?.getError('closedAt')}
                      style={{ width: 160, height: 56 }}
                    />
                  </div>

                  <div className="fx gap-8">
                    <div
                      className="fw-700 txt-black-01 lh-20"
                      style={{ width: 185 }}
                    >
                      Require resume to apply?
                      <span className="txt-red-02">*</span>
                    </div>
                    <div className="fx fx-ai-center gap-6">
                      <GroupRadio
                        defaultValue={formData.requiredResume}
                        name="requiredResume"
                        options={CAMPAIGN_RESUME_TO_APPLY_OPTIONS}
                        onChange={(requiredResume) => {
                          return setFormData((prev) => ({
                            ...prev,
                            requiredResume: requiredResume as boolean
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="fx gap-8">
                    <div
                      className="fw-700 txt-black-01 lh-20"
                      style={{ width: 185 }}
                    >
                      Is this job in the trades?
                      <span className="txt-red-02">*</span>
                    </div>
                    <div className="fx fx-ai-center gap-6">
                      <GroupRadio
                        defaultValue={formData.canTrade}
                        name="canTrade"
                        options={CAMPAIGN_TRADE_OPTIONS}
                        onChange={(canTrade) =>
                          setFormData((prev) => ({
                            ...prev,
                            canTrade: canTrade as boolean
                          }))}
                      />
                    </div>
                  </div>

                  {formData.canTrade && (
                    <div>
                      <Input
                        className="py-1"
                        name="specifyTrade"
                        placeholder="Specify trade..."
                        disabled={loading}
                        value={formData?.specifyTrade}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className="fx-column round-4"
                style={{ border: '1px solid #D3D3D3' }}
              >
                <div className="fs-16 fw-700 txt-black-01 px-4 py-5">
                  Your applicants will answer this question:
                </div>
                <Divider color="#DADBE7"/>
                <Input
                  name="question"
                  readOnly
                  value={formData?.question}
                  wrapperClassName={clsx(Style.questionInput, 'px-4 py-5')}
                />
              </div>

              <div
                className="fx-column round-4"
                style={{ border: '1px solid #D3D3D3' }}
              >
                <div className="fw-700 txt-black-01 px-4 py-5">
                  Job Description<span className="txt-red-02">*</span>
                </div>
                <Editor
                  className="round-4"
                  disabled
                  readOnly
                  value={formData.briefDescription}
                />
              </div>
            </div>

            <CompanyInfo/>
          </div>
        )
        : (
          <CampaignCompanyVerify
            isClaim
            campaign={formData}
            loading={loading}
            errors={errors}
            onChange={setFormData}
          />
        )}

      <div className={Style.action}>
        <div className="fx fx-ai-center gap-3">
          <Button
            variant="secondary"
            disabled={loading || !formData.uploadVideoId}
            onClick={() => DialogService.open(ModalCampaignDetail, { campaign: { ...formData, author: profile } })}
          >
            Preview
          </Button>
          <Button
            variant="label"
            onClick={onSubmit}
            disabled={loading || !formData.uploadVideoId}
          >
            {stage === 'info' && !isVerify ? 'Next' : 'Confirm'}
          </Button>
        </div>
      </div>
    </>
  )
}
