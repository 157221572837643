import qs from 'qs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { VibeApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { BreadcrumbHeading, Button, InputDebounce, Spinner } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IconSearch } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignCard } from 'src/partials/card/campaign-card'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { ERoutes, browserHistory, generate } from 'src/router'
import { DialogService, LoadMoreService } from 'src/services'
import { AuthModule } from 'src/store'
import Style from './style.module.scss'

export const ClaimJobs: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [keyword, setKeyword] = useState<string>()

  const _loadMoreService = useMemo(() => new LoadMoreService<ICampaignModel>({
    axiosInstance: axiosHiringApi,
    endpoint: VibeApi._vibeCampaign + '?' + qs.stringify({
      keyword
    })
  }), [keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  useEffect(() => {
    _loadMoreService.loadMore()
  }, [_loadMoreService])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>

          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search jobs, skills, locations..."
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      <div className="fx-1 fx-column gap-5 p-8 bg-neutral-20">
        <div className="fx fx-ai-center gap-4">
          <div className="semibold-32 txt-black-01">
            Claim a Listing
          </div>
          <Button onClick={() => browserHistory.push(generate(isAuthenticated ? ERoutes.CREATE_CAMPAIGN : ERoutes.SIGN_IN))}>
            Skip Step
          </Button>
        </div>

        <div className="fx-1 fx fx-column gap-4 overflow-auto relative">
          <div className={Style.jobsGrid}>
            {items.map((campaign) => (
              <CampaignCard
                key={campaign.id}
                campaign={campaign as unknown as ICampaignModel}
                onViewDetails={() => DialogService.open(ModalCampaignDetail, { campaign: { ...campaign, author: profile } })}
              />
            ))}
          </div>
          {debounceLoading && (
            <div className="fx-1 fx fx-center">
              <Spinner/>
            </div>
          )}
          <div ref={refLoadMore}/>
        </div>
      </div>
    </>
  )
}
