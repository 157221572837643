import { Dispatch, FC, SetStateAction } from 'react'
import { Input, InputPhoneNumber } from 'src/components'
import { ErrorBag } from 'src/hooks'
import { ICampaignModel } from 'src/interfaces'

interface IProps {
  isClaim?: boolean
  campaign: Partial<ICampaignModel> & {
    companyPhoneNo?: string
    linkedIn?: string
  }
  errors?: ErrorBag<Partial<ICampaignModel> & {
    companyPhoneNo?: string
    linkedIn?: string
  }>
  loading?: boolean
  onChange: Dispatch<SetStateAction<Partial<ICampaignModel> & {
    companyPhoneNo?: string
    linkedIn?: string
  }>>
}

export const CampaignCompanyVerify: FC<IProps> = ({ isClaim, campaign, loading, errors, onChange }) => {
  return (
    <div className="fx-1 fx-column gap-8 p-8 bg-neutral-20 w-100-p">
      <div className="fx-column gap-3">
        <div className="fs-28 fw-600 txt-black-01">{isClaim ? 'Claim a Job' : 'Create a Listing'}</div>
        <div className="fs-16 fw-600 txt-grey-01">{isClaim ? 'Verify Company' : 'Verifications'}</div>
      </div>

      <div
        className="p-8 bg-neutral-white round-4 fx-column gap-10"
        style={{ maxWidth: 950 }}
      >
        <div className="fx-column gap-6">
          <div className="fx-column gap-1">
            <div className="fs-16 fw-700 lh-20 txt-black-01">To verify your job listing, please provide your company phone number.</div>
            <div className="lh-20 txt-black-02">The verification process may take up to 3 business days. If verification is successful, your job listing will be published.</div>
          </div>

          <div className="fx-column gap-2">
            <div className="fw-700 txt-black-01 lh-20">
              Company Phone Number<span className="txt-red-02">*</span>
            </div>
            <InputPhoneNumber
              variant="secondary"
              name="companyPhoneNo"
              placeholder="181-888-8282"
              disabled={loading}
              value={campaign?.companyPhoneNo}
              error={errors?.getError('companyPhoneNo')}
              onChange={(e) => onChange((prev) => ({
                ...prev,
                companyPhoneNo: e.target.value?.replace(/[^0-9]/g, '')
              }))}
            />
          </div>

          <div className="fx-column gap-2">
            <div className="fw-700 txt-black-01 lh-20">
              Link to Your LinkedIn Profile
            </div>
            <Input
              className="py-1"
              name="linkedIn"
              placeholder="linkedin.com/in/"
              disabled={loading}
              value={campaign?.linkedIn}
              onChange={(e) => onChange((prev) => ({
                ...prev,
                linkedIn: e.target.value
              }))}
            />

            <div className="lh-20 txt-grey-01 mt-1">Providing a link to your Linkedin profile will help expedite the verification process.</div>
          </div>
        </div>
      </div>
    </div>
  )
}
