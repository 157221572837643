import { FC } from 'react'
import { Button } from 'src/components'
import { VideoPlayerNew } from 'src/components/video-player-new'
import { IconDownload } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { ModalMyVideoDetail } from 'src/partials/modal-my-video-detail'
import { DialogService } from 'src/services'
import { FileUtils, StyleUtils, VideoUtils } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  video: IVideoModel & { usedForCampaigns?: number }
  onChange?: (values: Partial<IVideoModel>) => void
  onSelectVideo?: (video: IVideoModel) => void
}

export const VVCCard: FC<IProps> = ({ video, onChange, onSelectVideo }) => {
  return (
    <div className={Style.vccContainer}>
      <div
        className="w-full round-3 pointer"
        style={{
          aspectRatio: '9/16',
          ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
        }}
      >
        <VideoPlayerNew
          url={VideoUtils.getVideoSource(video) ?? ''}
          tracks={video?.tracks}
          image={video?.urlVideoImageThumbnail}
        />
      </div>

      <div className={Style.vvcInfo}>
        <div className="fx fx-column gap-1">
          {/* {video.title && <div className="fs-20 fw-600 -24 txt-black-01 three-dot-1">{video.title || '---'}</div>} */}
          <div className="fs-12 fw-400 lh-16 txt-grey-01">Used for {video.usedForCampaigns || 0} jobs</div>
        </div>
        <div className="fx fx-ai-center gap-2">
          {onSelectVideo && (
            <Button
              variant="label"
              className="w-fit"
              onClick={() => onSelectVideo?.(video)}
            >
              Pick video
            </Button>
          )}

          <Button
            className="w-fit"
            onClick={() => DialogService.open(ModalMyVideoDetail, { video, onChange })}
          >
            Details
          </Button>

          {video?.urlVideoSource && (
            <Button variant="icon" onClick={() => FileUtils.download(video?.urlVideoSource ?? '', video.title)}>
              <IconDownload/>
            </Button>
          )}

          {/* <Button variant="icon">
            <IconEdit/>
          </Button> */}
        </div>
      </div>
    </div>
  )
}
