import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { Loading } from 'src/components'
import { EPaletteMode, TTheme, toCssVariables } from 'src/constants'
import { useBehaviorMapper, useDebounce } from 'src/hooks'
import { ModalCompleteSignUp } from 'src/partials'
import { DialogService, OverlayService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { DialogContainer } from './dialog-container'
import { Overlay } from './overlay'
import { RouterView } from './router-view'
import { SnackbarContainer } from './snackbar-container'
import Style from './style.module.scss'

export * from './overlay'

const createThemeVariables = makeStyles<TTheme>(
  () => ({
    '@global': {
      ':root': () => toCssVariables(EPaletteMode.LIGHT)
    }
  })
)

export const AppLayout: FC = () => {
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)
  createThemeVariables()

  const profile = useBehaviorMapper(AuthModule.profile$)
  useDebounce(() => {
    if (profile?.id && !profile.companies?.length) {
      DialogService.open(ModalCompleteSignUp, {}, { persist: true })
    }
  }, 1000, [profile])

  return (
    <div className={clsx(Style.layout, { [Style.loading]: isLayoutLoading })}>
      <DialogContainer/>
      <SnackbarContainer/>

      <main className="fx-1 fx fx-column">
        <RouterView/>
      </main>

      {appOverlay.open && <Overlay/>}

      <Loading show={isLayoutLoading}/>
    </div>
  )
}
