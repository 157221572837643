import { Box } from '@mui/material'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardCvcElementChangeEvent, StripeCardElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElement, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js'
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import { PaymentApi } from 'src/api'
import { Button, ErrorMessage, Label, useAnalytic } from 'src/components'
import { ETrackingEvent, EUpgradeView } from 'src/enums'
import { useAppDispatch, useBehaviorMapper } from 'src/hooks'
import { IObject } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { setPaymentIntentId } from 'src/store/actions/payment'
import { IUpgradePlan } from '../store'
import { STCartElement, STStripe, useStyles } from './styled'
import { mapStripeError } from './utils'

const CardInputOptions = {
  style: {
    border: '1px solid red',
    base: {
      color: '#24252D',
      fontFamily: 'Inter,',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '28px',

      '::placeholder': {
        color: '#80818E'
      }
    },
    invalid: {
      color: '#EA2E4E',
      iconColor: '#EA2E4E'
    }
  }
}

type StripeElementChangeEvent = StripeCardElementChangeEvent | StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent

interface IProps {
  setView: Dispatch<SetStateAction<number>>
  plan: IUpgradePlan | null
  quantity: number
}

export const Stripe: FC<IProps> = ({ setView, plan, quantity }) => {
  const classes = useStyles()
  const timeout = useRef<NodeJS.Timeout>()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const profile = useBehaviorMapper(AuthModule.formattedProfile$)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const { eventHandler } = useAnalytic('')

  const handleDetectStripeConnection = (isReady: boolean) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    // if (isReady) {
    //   return setLoading((prev) => false)
    // }

    timeout.current = setTimeout(() => {
      if (!isReady) {
        return SnackbarService.warn('Bummer, Network Interruption, Try Again')
      }
    }, 10000)
  }

  const onStripeReady = () => handleDetectStripeConnection(true)

  const onCardChange = (event: StripeElementChangeEvent) => {
    const message = event.error?.message

    if (message) {
      return setErrors((prev) => ({
        ...prev,
        [event.elementType]: message
      }))
    }

    setErrors(
      (prev) => Object.keys(prev).reduce<IObject>((acc, key) => {
        if (key !== event.elementType) {
          acc[key] = prev[key]
        }

        return acc
      }, {})
    )
  }

  const handlePay = async () => {
    if (!stripe || !elements || !plan) return

    LoadingModule.toggle(true)

    const { data } = await PaymentApi.create({
      items: [{ credits: plan.intros, qty: quantity }]
    })

    const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      billing_details: {
        email: profile.email,
        name: profile.fullName
      }
    })

    if (paymentMethodError) {
      LoadingModule.toggle(false)
      const errorMessage = mapStripeError(paymentMethodError) || 'Something went wrong. Please try again.'
      return SnackbarService.error(errorMessage)
    }

    const { paymentIntent, error: paymentIntentError } = await stripe.confirmCardPayment(data.clientSecret, {
      payment_method: paymentMethod.id
    })

    if (paymentIntentError) {
      LoadingModule.toggle(false)
      const errorMessage = mapStripeError(paymentIntentError) || 'Something went wrong. Please try again.'
      return SnackbarService.error(errorMessage)
    }

    // trigger load payment list
    if (paymentIntent.id) {
      dispatch(setPaymentIntentId(paymentIntent.id))
    }

    LoadingModule.toggle(false)
    setView(EUpgradeView.PAYMENT_SUCCESS)
  }

  return (
    <STStripe>
      <STCartElement>
        <Label>Card Number</Label>
        <CardNumberElement
          onReady={onStripeReady}
          onChange={onCardChange}
          onFocus={eventHandler(ETrackingEvent.INPUT_CC)}
          className={errors.cardNumber ? classes.StripeInputError : classes.StripeInput}
          options={{ ...CardInputOptions, showIcon: true, placeholder: '---- ---- ---- ----' }}
        />
        <ErrorMessage className="mt-2">{errors.cardNumber}</ErrorMessage>
      </STCartElement>

      <Box display="flex" gap={2} mt={4}>
        <STCartElement width={160}>
          <Label>Expiration</Label>
          <CardExpiryElement
            onReady={onStripeReady}
            onChange={onCardChange}
            onFocus={eventHandler(ETrackingEvent.INPUT_CC_EXP)}
            className={errors.cardExpiry ? classes.StripeInputError : classes.StripeInput}
            options={{ ...CardInputOptions, placeholder: 'MM/YY' }}
          />
          <ErrorMessage className="mt-2">{errors.cardExpiry}</ErrorMessage>
        </STCartElement>

        <STCartElement width={160}>
          <Label>CVV</Label>
          <CardCvcElement
            onReady={onStripeReady}
            onChange={onCardChange}
            onFocus={eventHandler(ETrackingEvent.INPUT_CC_CVV)}
            className={errors.cardCvc ? classes.StripeInputError : classes.StripeInput}
            options={{ ...CardInputOptions, placeholder: 'CVV' }}
          />
          <ErrorMessage className="mt-2">{errors.cardCvc}</ErrorMessage>
        </STCartElement>
      </Box>

      <Button className="w-100-p mt-4" onClick={eventHandler(ETrackingEvent.BTN_PAY, handlePay)}>Pay</Button>
    </STStripe>
  )
}
