import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory, useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { BreadcrumbDivider, BreadcrumbHeading, InputDebounce, Modal, Spinner } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading, useUnsubscribeEffect } from 'src/hooks'
import { IconSearch } from 'src/icons'
import { ICampaignModel, ICampaignSubmissionModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignApplicantCard } from 'src/partials/card/campaign-applicant-card'
import { ModalApplicantDetail } from 'src/partials/modal-applicant-detail'
import { ERoutes, generate } from 'src/router'
import { LoadMoreService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { ECampaignRoutes } from '../../routes.enum'

export const Applicants: FC = () => {
  const history = useHistory()
  const [keyword, setKeyword] = useState<string>()
  const { campaignId } = useParams<{ campaignId: string }>()
  const [campaign, setCampaign] = useState<ICampaignModel>()

  const _loadMoreService = useMemo(() => new LoadMoreService<ICampaignSubmissionModel>({
    axiosInstance: axiosHiringApi,
    endpoint: `${CampaignApi._prefix}/${campaignId}/submission?` + qs.stringify({
      keyword
    })
  }), [campaignId, keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!campaignId) return

    from(CampaignApi.getOne(Number(campaignId)))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          history.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        setCampaign(data)
      })
  }, [campaignId])

  useEffect(() => {
    if (campaignId && inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, campaignId, inView, loading])

  const debounceLoading = useDebouncedLoading(!items.length && loading)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(() => currentIndex + 1 === items?.length, [currentIndex, items?.length])

  const onDetail = (index: number) => {
    setCurrentIndex(index)
    setOpen(true)
  }

  const onPrevious = useCallback(() => setCurrentIndex(prev => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex(prev => prev + 1)

    if (currentIndex === items.length - 2 && isAuthenticated) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, currentIndex, isAuthenticated, items.length])

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <div className="fx fx-ai-center gap-3">
            <BreadcrumbHeading onClick={() => history.push(generate(ECampaignRoutes.CAMPAIGN_MY_JOBS))} className="pointer">My Jobs</BreadcrumbHeading>
            <BreadcrumbDivider/>
            <BreadcrumbHeading active>{campaign?.jobTitle}</BreadcrumbHeading>
          </div>
          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search applicants…"
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      {debounceLoading && (
        <div className="fx-1 fx fx-center">
          <Spinner/>
        </div>
      )}

      <section
        className="p-8 "
        style={{
          height: debounceLoading ? '0' : undefined,
          opacity: debounceLoading ? 0 : undefined
        }}
      >
        <div className="fs-28 fw-600 txt-black-01 mb-8">
          {campaign?.jobTitle}
        </div>
        <div className="fx fx-wrap-wrap gap-4">
          {items?.map((item, index) => (
            <CampaignApplicantCard
              key={item.id}
              submission={item}
              onChange={(id, values) => id && _loadMoreService.updateOneItem(id, values)}
              onDetail={() => onDetail(index)}
            />
          ))}
          <div ref={refLoadMore}/>
        </div>
      </section>

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          closeBtn
          contentClass="fx-1 fx fx-center"
        >
          <ModalApplicantDetail
            submission={items[currentIndex]}
            canPreviousProps={canPreviousProps}
            canNextProps={canNextProps}
            onPrevious={onPrevious}
            onNext={onNext}
            onChange={(id, values) => id && _loadMoreService.updateOneItem(id, values)}
          />
        </Modal>
      )}
    </>

  )
}
