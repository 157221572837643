import {
  Dispatch,
  FC,
  HTMLAttributes,
  useCallback,
  useMemo
} from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button, DialogConfirmUnMatch } from 'src/components'
import { MenuDropdown } from 'src/components/menu-dropdown'
import { useUnsubscribe } from 'src/hooks'
import { IconKebab, IconLoopkup } from 'src/icons'
import { IConversationModel } from 'src/interfaces'
import { ModalReport } from 'src/partials/modal-report'
import { DialogService, SnackbarService } from 'src/services'
import { MessengerService } from 'src/services/messenger.service'
import Style from './style.module.scss'

interface IProps {
  conversation: IConversationModel
  openAside: boolean
  setOpenAside: Dispatch<React.SetStateAction<boolean>>
  removeConversation: (id: number) => void
}

export const Header: FC<HTMLAttributes<HTMLDivElement> & IProps> = ({
  conversation,
  openAside,
  setOpenAside,
  removeConversation,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()

  const fullname = useMemo(
    () => MessengerService.getConversationUserFullName(conversation),
    [conversation]
  )
  const username = useMemo(
    () => MessengerService.getConversationUserName(conversation),
    [conversation]
  )

  const user = useMemo(
    () => MessengerService.getConversationUser(conversation),
    [conversation]
  )

  const onToggleLike = useCallback(() => {
    if (!user?.userId) return

    from(PeopleApi.toggleLike({ id: user?.userId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
      .subscribe(() => {
        if (conversation?.id) {
          removeConversation(conversation?.id)
          MessengerService.setConversation()
        }
      })
  }, [conversation?.id, removeConversation, unsubscribe$, user?.userId])

  return (
    <div {...props} className="fx fx-ai-center fx-jc-space-between px-7 py-5">
      <div className="fx fx-column gap-2">
        <div className="three-dot-1 fs-16 fw-500 lh-20 txt-black-01">{fullname}</div>
        {username && <div className="fs-12 txt-grey-01 three-dot-1">@{username}</div>}
      </div>
      <div className="fx fx-ai-center gap-3">
        <Button
          size={32}
          variant="icon"
          black={openAside}
          onClick={() => setOpenAside(prev => !prev)}
        >
          <IconLoopkup color={openAside ? '#fff' : '#0C0C0C'}/>
        </Button>

        <MenuDropdown
          dropdownRender={(
            <div className={Style.dropdown}>
              <div
                className="fs-14 fw-500 lh-20 txt-black-02 py-3 px-5 pointer"
                onClick={() => DialogService.open(DialogConfirmUnMatch, {
                  name: fullname,
                  onConfirm: onToggleLike
                })}
              >
                Unmatch
              </div>
              <div className="fs-14 fw-500 lh-20 txt-black-02 py-3 px-5 pointer" onClick={() => DialogService.open(ModalReport, { relateId: user?.userId })}>Report</div>
            </div>
          )}
        >
          <Button
            size={32}
            variant="icon"
          >
            <IconKebab/>
          </Button>
        </MenuDropdown>
      </div>
    </div>
  )
}
