import { Box, Typography } from '@mui/material'
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { AuthApi, ProfileApi } from 'src/api'
import { Button, Checkbox, Input, useAnalytic } from 'src/components'
import { RE_URL_WITH_OUT_PROTOCOL } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useOldValidation } from 'src/hooks'
import { IconPlus, IconTrash } from 'src/icons'
import { OverlayService, SnackbarService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { getApiErrorMessage } from 'src/utils'
import { completeProfileSchema } from 'src/validation'
import Style from './style.module.scss'

export const CompleteProfile: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const profile = useBehaviorMapper(AuthModule.formattedProfile$)
  const [formData, setFormData] = useState({
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
    companyName: profile.companyName || '',
    companyUrls: profile.companyUrls || [],
    subscribeMarketingEmails: false
  })

  const [companyUrls, setCompanyUrls] = useState(profile?.companyUrls || [''])

  const { errors, validateAt, validate, setErrors } = useOldValidation<typeof formData>()
  const { eventHandler } = useAnalytic('')

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    setErrors((prev) => ({ ...prev, [name]: '' }))
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: completeProfileSchema,
      path: name,
      data: { [name]: value }
    })
  }

  const handleUpdate = async () => {
    try {
      LoadingModule.toggle(true)
      const isValid = await validate({
        schema: completeProfileSchema,
        data: formData
      })

      if (!isValid) return

      await AuthApi.complete({
        ...formData,
        companyUrls: companyUrls?.map(url => url.toLowerCase()) || []
      })

      ProfileApi.getProfile().then(({ data }) => AuthModule.authenticated(data))
      OverlayService.reset()

      /**
       * If in likes or intros page, reload the page to get the candidates
       */
      if (['/likes', '/intros'].includes(location.pathname)) {
        history.go(0)
      }

      SnackbarService.success('Profile Update Successful')
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      LoadingModule.toggle(false)
    }
  }

  useEffect(() => {
    if (!Array.isArray(profile.companyUrls) || profile.companyUrls?.length === 0) {
      setCompanyUrls([''])
      return
    }

    setCompanyUrls(profile.companyUrls)
  }, [profile.companyUrls])

  const addMoreCompanyLink = useCallback(() => {
    if (companyUrls.length > 3) return

    setCompanyUrls([...companyUrls, ''])
  }, [companyUrls])

  const removeCompanyLink = (index: number) => {
    if (companyUrls.length === 1) return

    const newCompanyUrls = [...companyUrls]
    newCompanyUrls.splice(index, 1)
    setCompanyUrls(newCompanyUrls)
  }

  const handleChangeCompanyLink = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target
    const newCompanyUrls = [...companyUrls]
    newCompanyUrls[index] = value
    setCompanyUrls(newCompanyUrls)

    const isValid = RE_URL_WITH_OUT_PROTOCOL.test(value)
    const _errors = errors.companyUrls || []
    _errors[index] = isValid ? '' : 'Invalid URL'
    setErrors((prev) => ({ ...prev, companyUrls: _errors }))
  }

  const shouldDisabled = useMemo(() => {
    return !!errors.firstName || !!errors.lastName || !!errors.companyName || !!errors.companyUrls?.some(url => !!url) || !formData.firstName || !formData.lastName || !formData.companyName || companyUrls?.some(url => !url)
  }, [companyUrls, errors.companyName, errors.companyUrls, errors.firstName, errors.lastName, formData.companyName, formData.firstName, formData.lastName])

  return (
    <div className={Style.completeProfile}>
      <Typography variant="h5">Complete your profile</Typography>
      <Box display="flex" gap={2} mt={4}>
        <Input
          label="First Name"
          id="firstName"
          name="firstName"
          placeholder="Enter your first name"
          value={formData.firstName}
          onChange={handleChange}
          onClick={eventHandler(ETrackingEvent.INPUT_FIRST_NAME)}
          onBlur={handleBlur}
          autoComplete="off"
          error={errors.firstName}
          required
        />
        <Input
          label="Last Name"
          id="lastName"
          name="lastName"
          placeholder="Enter your last name"
          value={formData.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={eventHandler(ETrackingEvent.INPUT_LAST_NAME)}
          autoComplete="off"
          error={errors.lastName}
          required
        />
      </Box>
      <Input
        label="Company name"
        name="companyName"
        placeholder="Write your company name"
        value={formData.companyName}
        onClick={eventHandler(ETrackingEvent.INPUT_COMPANY)}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        required
        error={errors.companyName}
      />

      <div className="mb-4 gap-2 fx flex-column">
        <span className={Style.title}>Company Link</span>
        {companyUrls?.map((url, index) => (
          <div key={index} className={Style.companyWebsites_inputWrapper}>
            <Input
              name="companyUrl"
              placeholder="Add your company’s website, Facebook, Linkerdin,...."
              value={url}
              error={errors.companyUrls?.[index] || ''}
              onChange={(e) => handleChangeCompanyLink(e, index)}
              onClick={eventHandler(ETrackingEvent.INPUT_COMPANY_URL)}
              autoComplete="off"
              required
            />
            {index > 0 && (
              <Button
                variant="icon"
                className={Style.companyWebsites_buttonRemoveWebsite}
                onClick={() => removeCompanyLink(index)}
              >
                <IconTrash width={18.5} height={20.25}/>
              </Button>
            )}
          </div>
        ))}
        <Button
          className={Style.companyWebsites_buttonMore}
          variant="secondary"
          disabled={companyUrls.length >= 3}
          onClick={addMoreCompanyLink}
        >
          <IconPlus width={16} height={16}/>
          More
        </Button>
      </div>

      <Checkbox
        checked={formData.subscribeMarketingEmails}
        label="Check to receive marketing emails from us"
        onClick={eventHandler(ETrackingEvent.CHKBOX_SPAM, () =>
          setFormData((prevState) => ({
            ...prevState,
            subscribeMarketingEmails: !prevState.subscribeMarketingEmails
          }))
        )}
      />

      <Button
        className="w-100-p mt-4"
        disabled={shouldDisabled}
        onClick={handleUpdate}
      >
        Submit
      </Button>
    </div>
  )
}
