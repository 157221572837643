import clsx from 'clsx'
import moment from 'moment'
import { FC, FocusEvent, InputHTMLAttributes, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IconCalendar, IconWarning } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  value?: Date
  onChange?: (date: string) => void
  placeholder?: string
  className?: string
  wrapperClassName?: string
  classNameInput?: string
  innerError?: string
  error?: boolean | string
  disabled?: boolean
  minDate?: Date
}

export const DateTimePicker: FC<Omit<InputHTMLAttributes<Element>, keyof IProps> & IProps> = ({
  value,
  onChange,
  placeholder = 'Select a date',
  ...props
}) => {
  const [focus, setFocus] = useState(false)

  return (
    <div className={clsx('fx-column gap-2', props.className)}>
      <div
        className={clsx(Style.datepicker, props.wrapperClassName, {
          [Style.error]: props.error,
          [Style.disabled]: props.disabled,
          [Style.focus]: focus
        })}
      >
        <DatePicker
          {...props}
          icon={<IconCalendar className={clsx('pointer', Style.calendarIcon)}/>}
          selected={value}
          onChange={(date: Date | null) => onChange?.(date ? moment(date).format('YYYY-MM-DD') : '')}
          dateFormat="MM/dd/yyyy"
          className={clsx(Style.datepickerInput, props.classNameInput)}
          onFocus={(e: FocusEvent<HTMLInputElement, Element>) => {
            props?.onFocus?.(e)
            setFocus(true)
          }}
          onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
            setFocus(false)
            props?.onBlur?.(e)
          }}
        />
      </div>

      {props.error && (
        <span className={clsx({ [Style.innerError]: props.innerError, meta: !props.innerError }, 'fx fx-ai-center gap-1 txt-negative-500')}>
          <IconWarning size={16}/>
          {props.error}
        </span>
      )}
    </div>
  )
}
