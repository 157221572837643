import { ISortMenuOption } from 'src/components'
import { EJobDescriptionType, ELocationType } from 'src/enums'
import { ESalaryRange, ESalaryRate } from 'src/interfaces'

export const CAMPAIGN_SALARY_RANGE_OPTIONS: ISortMenuOption<ESalaryRange>[] = [
  { label: 'Range', value: ESalaryRange.RANGE },
  { label: 'Custom range', value: ESalaryRange.CUSTOM },
  { label: 'Extra amount', value: ESalaryRange.EXTRA_AMOUNT }
]

export const CAMPAIGN_SALARY_RATE_OPTIONS: ISortMenuOption<ESalaryRate>[] = [
  { label: 'Per hour', value: ESalaryRate.PER_HOUR },
  { label: 'Per month', value: ESalaryRate.PER_MONTH },
  { label: 'Per year', value: ESalaryRate.PER_YEAR }
]

export const CAMPAIGN_SALARY_VALUE_RANGE_OPTIONS: ISortMenuOption<string>[] = [
  { label: '20,000 - 30,000', value: '20,000 - 30,000' },
  { label: '30,000 - 40,000', value: '30,000 - 40,000' },
  { label: '40,000 - 50,000', value: '40,000 - 50,000' },
  { label: '50,000 - 60,000', value: '50,000 - 60,000' },
  { label: '60,000 - 70,000', value: '60,000 - 70,000' }
]

export const CAMPAIGN_LOCATION_TYPE_OPTIONS = [
  { label: 'On-site', description: 'Employees come to work in-person.', value: ELocationType.ON_SITE },
  { label: 'Hybrid', description: 'Employees work on-site and off-site.', value: ELocationType.HYBRID },
  { label: 'Remote', description: 'Employees work off-site.', value: ELocationType.REMOTE }
]

export const CAMPAIGN_RESUME_TO_APPLY_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

export const CAMPAIGN_TRADE_OPTIONS = [
  { label: 'Yes – please specify', value: true },
  { label: 'No', value: false }
]

export const CAMPAIGN_DESCRIPTION_OPTIONS = [
  { label: 'Upload as attachment', value: EJobDescriptionType.UPLOAD_ATTACHMENT },
  { label: 'Enter Manually', value: EJobDescriptionType.MANUALLY }
]

export const PUBLIC_CAMPAIGN_BASE_PATH = 'public-campaigns'
export const GUEST_ONBOARDING_BASE_PATH = 'onboarding'
