import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, CampaignClaimApi } from 'src/api'
import { BreadcrumbHeading, Tabs, useAnalytic } from 'src/components'
import { Overlay } from 'src/components/overlay'
import { ECampaignStatus, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useQueryParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModelExtended, ITab } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { ERoutes, browserHistory, generate } from 'src/router'
import { PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { AuthModule, CampaignModule, LoadingModule, SidebarModule } from 'src/store'
import { getFirstCompany } from 'src/utils'
import { CampaignDetail } from '../detailV2/view-detail'
import { Sandbox } from '../sandbox'
import { CardJob } from './components/card-job'
import { CreateJobButton } from './components/create-job-button'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

const campaignStatus = {
  [ECampaignStatus.PUBLISHED]: 'Published',
  [ECampaignStatus.DRAFT]: 'Drafts',
  [ECampaignStatus.UNVERIFIED]: 'Unverified'
}

const tabs: ITab[] = [
  { title: campaignStatus[ECampaignStatus.PUBLISHED] },
  { title: campaignStatus[ECampaignStatus.DRAFT] },
  { title: campaignStatus[ECampaignStatus.UNVERIFIED] }
]

export const sandboxTabs = tabs.filter((tab) => tab.title !== campaignStatus[ECampaignStatus.UNVERIFIED])

export const MyJobs = () => {
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()
  const [_tab, setTab] = useState<number>(0)
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const shareJobTourEnabled = useBehaviorMapper(ShareJobTourService.enableTour$)
  const { eventHandler } = useAnalytic('')

  const isMax505 = useMediaQuery('(max-width:505px)')
  const { tab } = useQueryParams()
  const [data, setData] = useState<ICampaignModelExtended[]>([])
  const [counts, setCounts] = useState<{
    count: number
    campaign: number
    campaignDraft: number
    countClaimPending?: number
  }>()
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignModelExtended>()

  const profile = useBehaviorMapper(AuthModule.profile$)
  const isVerify = useMemo(() => getFirstCompany(profile)?.CompanyUser?.isVerified, [profile])

  const handleBannerVibeTalent = useCallback(() => {
    eventHandler(ETrackingEvent.BTN_VIBE_TALENT)()
    browserHistory.push('/vibes')
  }, [eventHandler])

  const fetchCount = useCallback(() => {
    if (!isAuthenticated) {
      return
    }

    LoadingModule.toggle(true)
    from(CampaignModule.fetchCounts())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(({ data }) => {
        setCounts({
          count: (data.countPublished || 0) + (data.countDraft || 0) + (data.countPending || 0),
          campaign: data.countPublished || 0,
          campaignDraft: data.countDraft || 0,
          countClaimPending: data.countPending || 0
        })
      })
  }, [isAuthenticated, unsubscribe$])

  const loadCampaign = useCallback(() => {
    from(CampaignApi.paginate({
      status: (+(tab || 0)) === 0
        ? ECampaignStatus.PUBLISHED
        : ECampaignStatus.DRAFT
    }))
      .pipe(finalize(() => LoadingModule.toggle(false)))
      .subscribe(({ data }) => setData(data))
  }, [tab])

  const loadCampaignClaim = useCallback(() => {
    from(CampaignClaimApi.paginate({ status: 'PENDING' }))
      .pipe(finalize(() => LoadingModule.toggle(false)))
      .subscribe(({ data }) => {
        const formatData = (data.rows || []) as ICampaignModelExtended[]
        setData(formatData)
      })
  }, [])

  useEffect(() => {
    fetchCount()
  }, [fetchCount])

  useEffect(() => {
    if (!counts?.count) {
      return
    }

    (+(tab || 0)) === 2
      ? loadCampaignClaim()
      : loadCampaign()
  }, [tab, counts?.count, loadCampaignClaim, loadCampaign])

  const handleSetTab = useCallback((newTab: number) => {
    setTab(newTab)

    if (+(tab || 0) !== newTab) {
      history.replace({ search: `?tab=${newTab}` })
    }
  }, [history, tab])

  useEffect(() => {
    if (tab) {
      setTab(+tab)
    }
  }, [tab])

  const removeJobFromList = useCallback((data: { id?: number; draftId?: number }) => {
    PopupTourService.fetchMissionIfShowing()
    fetchCount()

    if (data.draftId) {
      setData(prev => prev.filter(item => item.draftId !== data.draftId))
      return
    }

    if (data.id) {
      setData(prev => prev.filter(item => item.id !== data.id))
    }
  }, [fetchCount])

  useEffect(() => {
    SidebarModule.on()
  }, [])

  const tabsWithCount = useMemo(() => {
    return tabs.reduce<ITab[]>((acc, tab) => {
      // hide unverified tab if no unverified campaign
      if (!counts?.countClaimPending && tab.title === campaignStatus[ECampaignStatus.UNVERIFIED]) {
        return acc
      }
      if (!counts) {
        return [...acc, tab]
      }
      const mapper = {
        [campaignStatus[ECampaignStatus.PUBLISHED]]: `${tab.title} (${counts?.campaign})`,
        [campaignStatus[ECampaignStatus.DRAFT]]: `${tab.title} (${counts?.campaignDraft})`,
        [campaignStatus[ECampaignStatus.UNVERIFIED]]: `${tab.title} (${counts?.countClaimPending})`
      }
      return [...acc, {
        ...tab,
        title: mapper[tab.title]
      }]
    }, [])
  }, [counts])

  const handleOpenCampaign = useCallback((item: ICampaignModelExtended) => setCurrentCampaign(item), [])

  const onClose = useCallback(() => setCurrentCampaign(undefined), [])

  if (shareJobTourEnabled) {
    return (
      <>
        <Breadcrumbs>
          <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
        </Breadcrumbs>

        <Sandbox/>
      </>
    )
  }

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      <div className={Style.container}>
        {!!counts?.count && (
          <div className={Style.headerMyJob}>
            <span className={Style.title}>
              My Jobs <span className={Style.count}>({counts?.count})</span>
            </span>

            <CreateJobButton className={clsx({ 'd-none': !isVerify && counts?.countClaimPending })} onlyIcon={isMax505} text="Create Job Posting" disabled={isLayoutLoading} onClick={() => browserHistory.push(generate(ERoutes.CLAIM_JOBS))}/>
          </div>
        )}

        <div className={Style.tab}>
          <Tabs tab={_tab} tabs={tabsWithCount} setTab={handleSetTab}/>
        </div>

        {data.length === 0
          ? (
            <EmptyJob
              tab={_tab || 0}
              withBanner={counts?.count === 0}
              onBannerClick={handleBannerVibeTalent}
            />
          )
          : (
            <div className={Style.content}>
              {data.map((item) => (
                <CardJob
                  key={(item.draftId ? 'draft-' : 'posted-') + item.id}
                  campaign={item}
                  onAfterDelete={removeJobFromList}
                  handleOpenCampaign={handleOpenCampaign}
                />
              ))}
            </div>
          )}

        {currentCampaign && (
          <Overlay>
            <CampaignDetail campaign={currentCampaign} onClose={onClose}/>
          </Overlay>
        )}
      </div>
    </>
  )
}
