import { Typography } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconVideoTutorial } from 'src/icons'
import { TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, OverlayService } from 'src/services'
import { CreateJobTourService, TARGET_CREATE_JOB_BUTTON } from 'src/services/tour/create-job-tour.service'
import Style from './style.module.scss'

interface IProps {
  onlyIcon?: boolean
  text?: string
  icon?: any
  disabled?: boolean
  className?: string
  /**
   * If you want to handle click event by yourself
   */
  onClick?: () => void
}

export const CreateJobButton: FC<IProps> = (props) => {
  const history = useHistory()
  const { text, icon, onlyIcon, disabled, onClick } = props
  const { eventHandler } = useAnalytic('campaigns')
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)

  const content = text ?? 'Create A Job'
  const prefixIcon = icon ?? <IconVideoTutorial/>

  const handleCreateCampaign = useCallback(() => {
    if (onClick) {
      return onClick()
    }

    CreateJobTourService.nextStep()
    OverlayService.reset()
    CampaignMutationService.reset()
    eventHandler(ETrackingEvent.CAMPAIGN_BTN_CREATE_JOBS)()
    history.push(generate(ERoutes.NEW_CAMPAIGN))
  }, [onClick, eventHandler, history])

  return (
    <>
      <Button
        id={TARGET_CREATE_JOB_BUTTON.getValue()}
        onClick={handleCreateCampaign}
        className={clsx(Style.button, 'round-16', props.className)}
        style={{ padding: '14px 32px' }}
        disabled={disabled}
      >
        {prefixIcon} {onlyIcon ? null : <Typography variant="body2-bold">{content}</Typography>}
      </Button>
      {tourEnabled && currentTourStep === 0 && <TourPopper {...CreateJobTourService.getStepConfig()}/>}
    </>
  )
}
