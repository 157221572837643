import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi, PeopleApi } from 'src/api'
import { Textarea } from 'src/components'
import { useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IconEmailStroke, IconLinkedInStroke, IconPhoneStroke } from 'src/icons'
import { ICandidateModel, IReactionModel } from 'src/interfaces'
import { TalentImageCard } from 'src/partials/card/talent-image-card'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { convertPhoneNumToInternationalFormat, removePrefixPhoneNumber, renderVideoTitle } from 'src/utils'
import Style from './style.module.scss'

export const TalentDetail = () => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const { lookupId } = useParams<{ lookupId: string }>()
  const [talent, setTalent] = useState<ICandidateModel>()
  const [privateNotes, setPrivateNotes] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const handleSavePrivateNotes = useCallback(() => {
    if (!talent?.id) return

    from(CandidateApi.note(talent.id, { content: privateNotes }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
  }, [talent?.id, privateNotes, unsubscribe$])

  const onToggleLike = useCallback(() => {
    if (!talent?.id) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: talent?.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setTalent(prev => ({ ...(prev as ICandidateModel), reaction: data as IReactionModel || null }))
      })
  }, [talent?.id, unsubscribe$])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!lookupId) return

    from(CandidateApi.detail(lookupId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          history.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        setTalent(data)
        data?.privateNote?.content && setPrivateNotes(data?.privateNote?.content)
      })
  }, [lookupId])

  return (
    <div className="p-8 fx fx-ai-center fx-jc-center">
      <div className={Style.talentDetail}>
        <div className={Style.videoContainer}>
          {talent && (
            <div className="fx fx-column gap-6">
              <TalentImageCard
                talent={talent}
                loading={loading}
                onToggleLike={onToggleLike}
              />
              <div className="fw-600 fs-20 lh-24 txt-black-01 three-dot-1 text-center">{renderVideoTitle(talent?.pfv)}</div>
            </div>
          )}
        </div>

        {/* TODO: clear duplicate */}
        <div className={Style.contactUser}>
          <div className="fx fx-column gap-3 mb-6 txt-grey-01">
            <div className="fw-500 txt-black-01">Contact</div>
            <div className="fx fx-ai-center gap-2">
              <IconPhoneStroke/>
              <div className="fs-12 three-dot-1">
                {talent?.userPhoneNo ? convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(talent?.userPhoneNo)) : '---'}
              </div>
            </div>
            <div className="fx fx-ai-center gap-2">
              <IconEmailStroke/>
              <div className="fs-12 three-dot-1">{talent?.email || '---'}</div>
            </div>
            <div className="fx fx-ai-center gap-2">
              <IconLinkedInStroke/>
              <div className="fs-12 three-dot-1">{talent?.linkedInUrl || '---'}</div>
            </div>
          </div>

          <div>
            <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
            <Textarea
              label=""
              placeholder="Only you can see your private notes"
              value={privateNotes}
              autoComplete="off"
              disableResize
              minRows={4}
              maxRows={6}
              style={{ marginTop: 8, fontSize: '14px', background: 'transparent' }}
              onChange={(event) => setPrivateNotes(event.target.value)}
              onBlur={handleSavePrivateNotes}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
