import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { useAsRef, useUnsubscribeEffect } from 'src/hooks'
import { Img } from 'src/images'
import { Spinner } from '../spinner'
import Style from './style.module.scss'

const LIMIT_X = 1920 / 2
const LIMIT_Y = 1080 / 2
const POSITIONS = [15, 50, 85]

interface IProps {
  disabled?: boolean
  src: File | Blob | string
  onSelect?: (cover: {
    image: File
    offset: number
  }) => void
}

export const VideoCoverPicker: FC<IProps> = (props) => {
  const { disabled, src } = props
  const [selected, setSelected] = useState<string>()
  const [images, setImages] = useState<string[]>([])
  const [options, setOptions] = useState<Array<{
    image: File
    offset: number
  }>>()

  useEffect(() => {
    setImages((prev) => {
      prev.map((image) => URL.revokeObjectURL(image))
      return options?.map((option) => URL.createObjectURL(option.image)) || []
    })
  }, [options])

  useEffect(() => {
    setSelected(images[0])
  }, [images])

  const onSelectRef = useAsRef(props.onSelect)
  useEffect(() => {
    if (selected) {
      const index = images.indexOf(selected)
      if (index > -1 && options?.[index]) {
        onSelectRef.current?.(options[index])
      }
    }
  }, [images, onSelectRef, options, selected])

  useUnsubscribeEffect((unsubscribe$) => {
    const videoUrl = typeof src === 'string'
      ? src
      : URL.createObjectURL(src)
    const video = document.createElement('video')
    const canvas = document.createElement('canvas')

    const promise = (async () => {
      const snapshots: Exclude<typeof options, undefined> = []

      /***
       * Wait for the video to load its metadata
       */
      /** const duration = */ await (() => new Promise<number>((resolve, reject) => {
        video.addEventListener('loadeddata', function () {
          resolve(video.duration)
        })
        video.preload = 'metadata'
        // Load video in Safari / IE11
        video.muted = true
        video.playsInline = true
        video.setAttribute('crossOrigin', '')
        video.src = videoUrl
      }))()

      for (const position of POSITIONS) {
        const snapshot = await new Promise<TArrayItem<typeof snapshots>>((resolve, reject) => {
          const snapImage = () => {
            // limit the size of the thumbnail to LIMIT_X x LIMIT_Y
            const minRatio = video.videoWidth > video.videoHeight
              ? Math.min(LIMIT_X / video.videoWidth, LIMIT_Y / video.videoHeight)
              : Math.min(LIMIT_Y / video.videoWidth, LIMIT_X / video.videoHeight)
            canvas.width = minRatio * video.videoWidth
            canvas.height = minRatio * video.videoHeight
            canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
            canvas.toBlob((blob) => {
              if (blob) {
                return resolve({
                  image: new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' }),
                  offset: video.currentTime
                  // duration: video.duration
                })
              }
              reject(new Error('no blob found'))
            }, 'image/jpeg', 0.8)
          }

          const videoTimeInSeconds = video.duration * position / 100
          video.currentTime = Number(videoTimeInSeconds.toString().match(/^-?\d+(?:\.\d{0,1})?/)?.[0]) || 0 // round to 1 decimal
          const onTimeUpdate = function () {
            snapImage()
            video.pause()
            video.removeEventListener('timeupdate', onTimeUpdate)
          }
          video.addEventListener('timeupdate', onTimeUpdate)
          video.play().then().catch(reject)
        })

        snapshots.push(snapshot)
      }

      return snapshots
    })()

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => {
          video.remove()
          canvas.remove()
          URL.revokeObjectURL(videoUrl)
        })
      )
      .subscribe(setOptions)
  }, [src])

  return (
    <div className="fx fx-column fx-ai-center fx-jc-center gap-4">
      {!options
        ? (
          <Spinner
            size={56}
            color="var(--color-positive-600, #32C37A)"
            className="fx-as-center"
          />
        )
        : (
          <div className="fx fx-column fx-ai-center gap-7">
            <div className={clsx('fx fx-center', Style.mainImageContainer)}>
              <Img src={selected} className={Style.image}/>
            </div>
            <div className="fx gap-4">
              {images.map((image, index) => (
                <Img
                  key={index}
                  src={image}
                  className={clsx(Style.image, {
                    pointer: !disabled && image !== selected,
                    [Style.selected]: image === selected
                  })}
                  onClick={disabled ? undefined : () => setSelected(image)}
                />
              ))}
            </div>
          </div>
        )}
    </div>
  )
}
