import { Box, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi } from 'src/api'
import { Button, Input, RequiredAsterisk } from 'src/components'
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { IconWarning } from 'src/icons'
import { Logo } from 'src/images'
import { browserHistory, ERoutes, generate } from 'src/router'
import { getApiErrorMessage } from 'src/utils'
import { workEmailSchema } from 'src/validation'
import Style from './style.module.scss'

interface IProps {
  inviteToken?: string
  onSuccess?: (email: string, inviteToken?: string) => void
  onSignIn?: () => void
}

export const RegisterEmailForm: FC<IProps> = ({ inviteToken, onSuccess, onSignIn }) => {
  const unsubscribe$ = useUnsubscribe()
  const [email, setEmail] = useState<string>('')
  const { errors, validate } = useValidation({
    data: useMemo(() => ({ email }), [email]),
    schema: workEmailSchema
  })

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { value } = e.target
    setEmail(value)
  }

  const [loading, setLoading] = useState(false)
  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const promise = AuthApi.signUpRequest({
      email,
      inviteToken,
      sessionId: AnalyticApi._session
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          errors.setError('email', getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        if (onSuccess) {
          return onSuccess(email, inviteToken)
        }
        browserHistory.push(generate(ERoutes.SIGN_UP, { inviteToken }), email)
      })
  }

  const goToLogin = useCallback(() => {
    if (onSignIn) {
      return onSignIn()
    }
    browserHistory.push(generate(ERoutes.SIGN_IN))
  }, [onSignIn])

  return (
    <form className={Style.signUpForm} onSubmit={handleSubmit}>
      <div className="mb-4">
        <img src={Logo} alt="logo"/>
      </div>

      <div className={Style.LoginHeader}>
        <Typography className={Style.Title}>Sign Up to KNOWME<span>TM</span></Typography>
        <Typography align="center" className={Style.Description}>
          Hiring
        </Typography>
      </div>

      <div className={Style.FormInput}>
        <Input
          label={<>Work email<RequiredAsterisk/></>}
          id="email"
          type="email"
          name="email"
          placeholder="name@company.com"
          disabled={loading}
          value={email || ''}
          onChange={handleChangeInput}
          error={errors.getError('email') === 'Looks like you already have an account. Try signing in.' ? true : errors.getError('email')}
        />

        {errors.getError('email') === 'Looks like you already have an account. Try signing in.' && (
          <div className="fx gap-1" style={{ marginTop: '-20px' }}>
            <span className="fx gap-1 txt-negative-500">
              <IconWarning size={16}/>
              Email already registered, sign in through
            </span>
            <b
              className="pointer txt-color-blue-500"
              onClick={goToLogin}
            >
              Hiring Manager Sign In
            </b>
          </div>
        )}

        <div className="body-14 txt-color-neutral-theme-300">
          By signing up, you accept KnowMe’s
          <b
            className="pointer txt-color-blue-500"
            onClick={() => window.open(TERMS_AND_SERVICE_URL, '_blank')}
          >
            &nbsp;Terms of Use&nbsp;
          </b>
          and acknowledge our
          <b
            className="pointer txt-color-blue-500"
            onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
          >
            &nbsp;Privacy Policy&nbsp;
          </b>
          .
        </div>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box className={Style.Box}>
            <Typography
              variant="body2-medium"
              color="var(--color-neutral-theme-900)"
            >
              You already have an account?
            </Typography>
            <b
              className="pointer txt-color-blue-500"
              onClick={goToLogin}
            >
              Sign in
            </b>
          </Box>
          <Button
            className="round-16"
            type="submit"
            disabled={loading}
          >
            Create Account
          </Button>
        </Box>
      </div>
    </form>
  )
}
