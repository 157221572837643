import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs'
import { BreadcrumbHeading } from 'src/components'
import { useUnsubscribe } from 'src/hooks'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { SidebarModule } from 'src/store'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

export const PublicGuestOnboarding: FC = () => {
  const unsubscribe$ = useUnsubscribe()

  useEffect(() => {
    SidebarModule.on()
  }, [])

  useEffect(() => {
    GuestOnboardingCreateJobTourService.completed$
      .pipe(
        takeUntil(unsubscribe$),
        filter(completed => completed),
        distinctUntilChanged()
      )
      .subscribe(() => {
        PopupTourService.showVVCPopup()
      })
  }, [unsubscribe$])

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      <div className={clsx(Style.containerGuest, Style.height100)}>
        <div className={clsx(Style.container, Style.height100)}>
          <EmptyJob/>
        </div>
      </div>
    </>
  )
}
