import { ComponentProps, FC } from 'react'
import { Modal } from 'src/components'
import { ICampaignModel } from 'src/interfaces'
import { CampaignDetail } from '../campaign-detail'

interface IProps {
  campaign: Partial<ICampaignModel>
}

export const ModalCampaignDetail: FC<ComponentProps<typeof Modal> & IProps> = ({ campaign, ...props }) => {
  return (
    <Modal
      {...props}
      closeBtn
      contentStyles={{
        borderRadius: 0,
        overflow: 'auto',
        height: 'var(--window-height, 100vh)'
      }}
    >
      <CampaignDetail campaign={campaign}/>
    </Modal>
  )
}
