import clsx from 'clsx'
import { forwardRef, useRef } from 'react'
import { Button, VideoPlayer, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconCameraPlus, IconPlayVideoInverse, IconUpload, IconVideo } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { ModalRecorderSample } from 'src/partials/recorder/modal-sample'
import { TourPopper } from 'src/partials/tour'
import { DialogService } from 'src/services'
import { CreateJobTourService, TARGET_SHOW_HUMAN_SIDE } from 'src/services/tour/create-job-tour.service'
import { FirstTimeClaimedJobTourService, TARGET_CLAIMED_JOB_VVC } from 'src/services/tour/first-time-claimed-job.service'
import { allowedAudioTypes, getVideoSource } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  video?: IVideoModel
  onRecordVideo?: () => void
  disableUpdateVideo?: boolean
}

export const IntroduceYourselfVideo = forwardRef<HTMLDivElement, IProps>(
  (
    {
      video,
      onRecordVideo,
      disableUpdateVideo
    },
    ref
  ) => {
    const { eventHandler } = useAnalytic('')
    const recordResultRef = useRef<HTMLInputElement>(null)

    const createJobTourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
    const createJobTourCurrentStep = useBehaviorMapper(CreateJobTourService.currentStep$)
    const claimedTourEnabled = useBehaviorMapper(FirstTimeClaimedJobTourService.enableTour$)
    const claimedTourCurrentStep = useBehaviorMapper(FirstTimeClaimedJobTourService.currentStep$)

    /**
     * @deprecated
     */
    const submitVideo = (record: File) => {
      console.log('TODO: no more inline upload supported, implement "from my videos" (https://www.figma.com/design/FZyP5GCKbG3ZglGVPXXFI3/KNOWME-%5BExternal%5D-(Copy-12%2F09%2F24)?node-id=6240-29640&t=0Of4i547jFukdQEC-4)')
      // onChangeUploadVideo?.(record)
      // FirstTimeClaimedJobTourService.stopTour()
      // TourApi.finish({ firstTimeClaimedJob: true })
      //   .then(() => ProfileApi.getProfile())
      //   .then(({ data }) => AuthModule.authenticated(data))
    }

    return (
      <>
        <input
          type="file"
          ref={recordResultRef}
          className="d-none"
          accept={['video/*', ...allowedAudioTypes].join(', ')}
          onChange={(e) => {
            if (!e.target.files?.[0]) return
            submitVideo(e.target.files?.[0])
            e.target.value = ''
          }}
        />

        <div className={Style.container} ref={ref} id={TARGET_CLAIMED_JOB_VVC.getValue()}>
          <div className={Style.showHumanSideTarget} id={TARGET_SHOW_HUMAN_SIDE.getValue()}/>
          {createJobTourEnabled && createJobTourCurrentStep === 1 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

          {!getVideoSource(video)
            ? (
              <div className={Style.emptyContainer}>
                <div className="fx gap-1 flex-column fx-ai-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 20H11C11 21.1 10.1 22 9 22C7.9 22 7 21.1 7 20ZM5 18C5 18.55 5.45 19 6 19H12C12.55 19 13 18.55 13 18C13 17.45 12.55 17 12 17H6C5.45 17 5 17.45 5 18ZM16.5 9.5C16.5 13.32 13.84 15.36 12.73 16H5.27C4.16 15.36 1.5 13.32 1.5 9.5C1.5 5.36 4.86 2 9 2C13.14 2 16.5 5.36 16.5 9.5ZM21.37 7.37L20 8L21.37 8.63L22 10L22.63 8.63L24 8L22.63 7.37L22 6L21.37 7.37ZM19 6L19.94 3.94L22 3L19.94 2.06L19 0L18.06 2.06L16 3L18.06 3.94L19 6Z" fill="#181920"/>
                  </svg>
                  <span className={clsx(Style.title_required, 'body2-bold color-neutral-theme-900 text-center')}>
                    Introduce Yourself With A Video Vibe Check
                  </span>
                  <Button
                    variant="secondary"
                    onClick={() => DialogService.open(ModalRecorderSample)}
                    className="mt-3"
                  >
                    <IconPlayVideoInverse width={12} height={13}/>
                    View Samples
                  </Button>
                </div>

                <div className={Style.recordZone}>
                  <IconVideo width={60} height={60}/>
                  <div className="fx flex-column fx-ai-center gap-2 w-100">
                    <Button
                      className="w-100-p"
                      onClick={onRecordVideo}
                    >
                      <IconCameraPlus width={18} height={18}/>
                      Record New Video
                    </Button>
                    <Button
                      className="w-100-p"
                      variant="secondary"
                      onClick={eventHandler(ETrackingEvent.RECORDING_BTN_REC_UPLOAD, () => recordResultRef.current?.click?.())}
                    >
                      <IconUpload width={17} height={17}/>
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            )
            : (
              <div className={Style.videoContainer}>
                <VideoPlayer
                  forceRender={!video?.urlVideoImageThumbnail}
                  className={Style.video}
                  url={getVideoSource(video)}
                  hideStartEnd
                  image={video?.urlVideoImageThumbnail}
                  showProcessing
                />
                {!disableUpdateVideo && (
                  <div className={Style.videoButtons}>
                    <Button
                      className="w-100-p"
                      onClick={onRecordVideo}
                    >
                      <IconCameraPlus width={18} height={18}/>
                      Record New Video
                    </Button>
                    {/* <Button
                      className="w-100-p"
                      variant="secondary"
                      onClick={eventHandler(ETrackingEvent.RECORDING_BTN_REC_UPLOAD, () => recordResultRef.current?.click?.())}
                    >
                      <IconUpload width={17} height={17}/>
                      Upload
                    </Button> */}
                  </div>
                )}
              </div>
            )}
        </div>

        {claimedTourEnabled && claimedTourCurrentStep === 1 && (
          <TourPopper {...FirstTimeClaimedJobTourService.getStepConfig()}/>
        )}
      </>
    )
  }
)
