import clsx from 'clsx'
import {
  ChangeEvent, ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { IReactMicStopEvent } from 'react-mic'
import { catchError, EMPTY, finalize, from, switchMap, takeUntil } from 'rxjs'
import { UploadApi, VideoApi } from 'src/api'
import {
  Button,
  DialogConfirm,
  isFirstTimeRecord,
  Modal,
  Tooltip,
  VideoCoverPicker,
  VideoRecorder
} from 'src/components'
import { VideoPlayerNew } from 'src/components/video-player-new'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import {
  IconDocumentStroke,
  IconDownloadStroke,
  IconInsightsStroke,
  IconReplace,
  IconUploadStroke,
  IconVideoTutorial
} from 'src/icons'
import { EFileUploadKind, IVideoModel } from 'src/interfaces'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { ModalRecorderSample } from '../modal-sample'
import { TeleprompterTextarea } from '../teleprompter-textarea'
import { RecorderModule } from './recorder.module'
import Style from './style.module.scss'

const Recording: FC<{ onNext: () => void }> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const uploadRecordRef = useRef<HTMLInputElement>(null)
  const audioBackgroundRef = useRef<HTMLInputElement>(null)

  const [placeholderSrc, setPlaceholderSrc] = useState<string>()
  const [recordFile, setRecordFile] = useState<File>()

  const onStopRecording = useCallback((recordOutput: Blob | File | IReactMicStopEvent) => {
    const result = recordOutput as IReactMicStopEvent
    const parsedOutput = result.blob || recordOutput
    RecorderModule.setRecordResult(parsedOutput)
    RecorderModule.stopRecording()
  }, [])

  const onFileChange = useCallback((
    event: ChangeEvent<HTMLInputElement>,
    ref: 'recordResult' | 'placeholder'
  ) => {
    if (!event.target.files?.length) {
      return
    }

    const file = event.target.files[0]
    event.target.value = ''

    switch (ref) {
      case 'placeholder':
        RecorderModule.setPlaceholder({ file })
        setPlaceholderSrc(URL.createObjectURL(file as Blob))
        break
      case 'recordResult':
        RecorderModule.setRecordResult(file)
        RecorderModule.stopRecording()
        break
    }
  }, [])

  const teleprompter = useBehaviorMapper(RecorderModule.teleprompter$)
  const [showNoteEditor, setShowNoteEditor] = useState(false)
  const menuItems = useMemo(() => [
    {
      icon: <IconUploadStroke size={24} color="currentColor"/>,
      text: 'Upload',
      title: '',
      onClick: () => { uploadRecordRef.current?.click() }
    },
    {
      icon: <IconInsightsStroke size={24}/>,
      text: 'Tips',
      title: <>If you need help getting started, <br/> click here for tips and examples.</>,
      onClick: () => { DialogService.open(ModalRecorderSample) }
    },
    {
      icon: <IconDocumentStroke size={24}/>,
      active: showNoteEditor,
      text: 'Notes',
      title: <>Write a script, and it will display <br/> on your screen for easy recording.</>,
      onClick: () => { setShowNoteEditor((prev) => !prev) }
    }
  ], [showNoteEditor])

  const [isOpenTooltips, setIsOpenTooltips] = useState(false)
  useEffect(() => {
    const isFirstTime = isFirstTimeRecord()
    setIsOpenTooltips(isFirstTime)
    const interval = setInterval(() => {
      const isFirstTime = isFirstTimeRecord()
      setIsOpenTooltips(isFirstTime)
      if (!isFirstTime) {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    RecorderModule.reset()

    RecorderModule.recordResult$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((result) => {
        if (!result) {
          return setRecordFile(undefined)
        }
        if (result instanceof File) {
          return setRecordFile(result)
        }
        setRecordFile(new File([result], 'any', { type: result.type }))
      })

    RecorderModule.placeholder$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((result) => {
        setPlaceholderSrc((prev) => {
          if (prev?.startsWith('blob:')) {
            URL.revokeObjectURL(prev)
          }
          return result?.file
            ? URL.createObjectURL(result.file as Blob)
            : result?.src
        })
      })
  }, [unsubscribe$])

  return (
    <>
      <div className={Style.screen}>
        <div className="d-none">
          <input
            type="file"
            ref={uploadRecordRef}
            accept={'video/*'}
            onChange={(event) => onFileChange(event, 'recordResult')}
          />
          <input
            type="file"
            ref={audioBackgroundRef}
            accept="image/*"
            onChange={(event) => onFileChange(event, 'placeholder')}
          />
        </div>

        <div className={Style.recorderWrapper}>
          {!recordFile && (
            <div className={Style.recorderMenu}>
              {menuItems.map(({ icon, text, title, active, onClick }, index) => (
                <Tooltip
                  key={index}
                  open={title ? isOpenTooltips || undefined : false}
                  title={title}
                  placement="right"
                >
                  <div className="fx-column fx-ai-center gap-1">
                    <Button
                      variant="icon"
                      size={40}
                      className={active ? '' : 'bg-grey-02-p'}
                      active={active}
                      onClick={onClick}
                    >
                      {icon}
                    </Button>
                    <span className="fs-12 fw-600">{text}</span>
                  </div>
                </Tooltip>
              ))}

              {showNoteEditor && (
                <TeleprompterTextarea
                  value={teleprompter?.text}
                  onChange={(e) => RecorderModule.setTeleprompter({ text: e.target.value })}
                />
              )}
            </div>
          )}

          <div className={Style.recorderView}>
            {!recordFile && (
              <VideoRecorder
                onStartRecording={() => RecorderModule.startRecording()}
                onStop={onStopRecording}
              />
            )}

            {recordFile && (
              <>
                <VideoPlayerNew
                  url={recordFile}
                  duration={RecorderModule.recordingDuration}
                  image={placeholderSrc}
                />

                <div
                  className="fx fx-center gap-2 px-4 absolute w-100-p"
                  style={{ bottom: '50px' }}
                >
                  <Button
                    className={clsx(Style.previewBtns, 'fx-1 gap-1 height-9')}
                    size="large"
                    onClick={() => DialogService.open(DialogConfirm, {
                      title: 'Start Over',
                      description: 'If you start over, you will lose this recording.',
                      cancelLabel: 'Keep This',
                      confirmLabel: 'Delete',
                      onConfirm: () => RecorderModule.reset()
                    })}
                  >
                    <IconReplace size={20}/>
                    Re-record
                  </Button>

                  <Button
                    className={clsx(Style.previewBtns, 'fx-1 gap-1 height-9')}
                    variant="secondary"
                    size="large"
                    onClick={() => RecorderModule.downloadRecordResult()}
                  >
                    <IconDownloadStroke size={20}/>
                    Download
                  </Button>

                  <Button
                    className={clsx(Style.previewBtns, 'fx-1 height-9')}
                    size="large"
                    variant="label"
                    active
                    onClick={props.onNext}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const PickThumbnail: FC<{ onComplete: (result?: { video: IVideoModel; thumbnail?: File }) => void }> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const recordResult = useBehaviorMapper(RecorderModule.recordResult$)
  const teleprompter = useBehaviorMapper(RecorderModule.teleprompter$)

  const [, setProgress] = useState<ProgressEvent>()
  const [loading, setLoading] = useState(false)
  const [thumbnail, setThumbnail] = useState<File>()
  const [thumbnailOffset, setThumbnailOffset] = useState<number>()
  const videoKeyRef = useRef<string>()

  /**
   * Prevent upload multiple times
   */
  const onUpload = useCallback((result: typeof recordResult) => {
    if (!result) {
      return Promise.reject(new Error('No record result'))
    }
    if (videoKeyRef.current) {
      return Promise.resolve(videoKeyRef.current)
    }
    return UploadApi.uploadF({
      file: result,
      kind: result.type.startsWith('audio')
        ? EFileUploadKind.AUDIO_VIBE_CHECK
        : EFileUploadKind.VIDEO_VIBE_CHECK
    }, { onUploadProgress: setProgress }).then((videoKey) => {
      videoKeyRef.current = videoKey
      return videoKey
    })

    // payload.key = await UploadApi.uploadF({
    //   file: recordResult,
    //   entity: 'Video',
    //   kind: recordResult.type.startsWith('audio')
    //     ? EFileUploadKind.AUDIO_VIBE_CHECK
    //     : EFileUploadKind.VIDEO_VIBE_CHECK
    // })
    // if (source.type.startsWith('audio')) {
    //   if (placeholder?.file) {
    //     // case 1: placeholder is image uploaded by user
    //     payload.photoKey = await UploadApi.upload({
    //       kind: EFileUploadKind.AUDIO_VIBE_PHOTO,
    //       file: placeholder.file
    //     })
    //   } else {
    //     // case 2: placeholder is image from library
    //     payload.photoKey = placeholder?.key
    //   }
    // }
  }, [])

  useEffect(() => {
    if (isAuthenticated && recordResult) {
      onUpload(recordResult)
    }
  }, [onUpload, isAuthenticated, recordResult])

  const onSubmit = useCallback<Required<ComponentProps<typeof Button>>['onClick']>(async (e) => {
    setLoading(true)
    LoadingModule.toggle(true)
    const waitTillHasKey = () => {
      if (videoKeyRef.current) {
        return Promise.resolve(videoKeyRef.current)
      }
      return new Promise<string>((resolve) => {
        const interval = setInterval(() => {
          if (videoKeyRef.current) {
            clearInterval(interval)
            resolve(videoKeyRef.current)
          }
        }, 300)
      })
    }

    from(waitTillHasKey())
      .pipe(
        switchMap((key) => VideoApi.create({
          key,
          transcription: teleprompter?.text,
          thumbnailOffset
        })),
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
          LoadingModule.toggle(false)
        })
      )
      .subscribe(({ data: video }) => {
        props.onComplete({
          video,
          thumbnail
        })
      })
  }, [props, teleprompter?.text, thumbnail, thumbnailOffset, unsubscribe$])

  return (
    <div className="fx-1 fx-column fx-center gap-10">
      {recordResult && (
        <VideoCoverPicker
          disabled={loading}
          src={recordResult}
          onSelect={({ image, offset }) => {
            setThumbnail(image)
            setThumbnailOffset(offset)
          }}
        />
      )}

      <div className="heading-14 txt-black-02 text-center">
        Choose a frame from your video <br/>
        to use as a thumbnail image.
      </div>

      <Button
        className="fx gap-2 py-3"
        variant="primary"
        disabled={loading || !isAuthenticated}
        onClick={onSubmit}
      >
        <IconVideoTutorial size={20} color="currentColor"/>
        <span>Submit Video For Job Post</span>
      </Button>
    </div>
  )
}

interface IProps {
  onClose?: ComponentProps<typeof PickThumbnail>['onComplete']
}

export const ModalRecorder: FC<Omit<ComponentProps<typeof Modal>, keyof IProps> & IProps> = (props) => {
  const [stage, setStage] = useState<'record' | 'select-thumbnail'>('record')

  return (
    <Modal
      {...props}
      onClose={() => props.onClose?.()}
      closeBtn
      contentClass="fx-column"
    >
      {stage === 'record'
        ? <Recording onNext={() => setStage('select-thumbnail')}/>
        : <PickThumbnail onComplete={(result) => props.onClose?.(result)}/>}
    </Modal>
  )
}
