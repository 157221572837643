import clsx from 'clsx'
import moment from 'moment'
import { ComponentProps, FC, useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { FilePreview, InfiniteScroll } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { AvatarDefault, Img } from 'src/images'
import { IMessageModel } from 'src/interfaces'
import { MessengerService } from 'src/services/messenger.service'
import { AuthModule } from 'src/store'
import { getAvatar } from 'src/utils'
import Style from './style.module.scss'

const Message: FC<{ message: IMessageModel }> = ({ message }) => {
  const profile = useBehaviorMapper(AuthModule.profile$)
  const conversation = useBehaviorMapper(MessengerService.conversation$)

  const isSender = message.userId === profile?.id
  const avatar = useMemo(
    () => {
      if (isSender) {
        return getAvatar(profile)
      }

      if (conversation) {
        return MessengerService.getConversationAvatar(conversation)
      }
    },
    [conversation, isSender, profile]
  )
  const title = useMemo(
    () => moment(message.createdAt).format('dddd ⋅ MMM DD, YYYY ⋅ HH:mm A'),
    [message.createdAt]
  )
  const fullname = useMemo(
    () => {
      if (isSender) {
        return 'Me'
      }

      if (conversation) {
        return MessengerService.getConversationUserFullName(conversation)
      }
    },
    [conversation, isSender]
  )
  const userCompany = useMemo(
    () => {
      if (isSender) {
        return profile?.email
      }

      if (conversation) {
        return MessengerService.getConversationUserCompany(conversation)?.CompanyUser?.userType
      }
    },
    [conversation, isSender, profile?.email]
  )

  const renderContents = useMemo(() => {
    const contents = []

    if (message.attachments?.length) {
      contents.push(
        <span title={title} className={clsx(Style.message, Style.attachments)}>
          <div>
            {message.attachments.map((attachment) => (
              <FilePreview
                key={attachment.id}
                src={attachment.url}
                fileName={attachment.filename}
                mimeType={attachment.contentType}
                allowInteraction
                size={
                  attachment.contentType?.startsWith('video/')
                    ? 'unset'
                    : '100%'
                }
                style={{
                  overflow: 'hidden',
                  maxHeight: '400px',
                  borderRadius: '10px'
                }}
              />
            ))}
          </div>
        </span>
      )
    }

    if (message.content) {
      contents.push(
        <span
          title={title}
          className={Style.message}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.content) }}
        />
      )
    }

    return contents
  }, [message, title])

  return (
    <>
      {renderContents.map((renderContent, index) => (
        <div
          key={index}
          className="px-6 pt-3 pb-2 fx gap-3"
        >
          <div>
            <Img
              src={avatar?.startsWith('http') ? avatar : AvatarDefault}
              width={40}
              height={40}
              alt="avatar"
              className="round-2 bg-neutral-20"
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className="fx fx-column gap-2">
            <div className="fx fx-ai-end gap-2">
              <div className="fs-16 fw-500 lh-16 txt-black-01">{fullname}</div>
              {userCompany && (
                <div className="fs-10 lh-12 txt-grey-01">{userCompany}</div>
              )}
            </div>
            <div className="">{renderContent}</div>
          </div>
        </div>
      ))}
    </>
  )
}

export const Messages: FC<Pick<ComponentProps<typeof InfiniteScroll>, 'hasMore' | 'next'> & {
  messages: IMessageModel[]
}> = ({
  messages,
  hasMore,
  next
}) => {
  return (
    <InfiniteScroll
      className="fx-1 fx"
      style={{ flexDirection: 'column-reverse' }}
      loader={null}
      inverse
      scrollThreshold={0.95} // 95% scrollHeight
      dataLength={messages.length}
      hasMore={hasMore}
      next={next}
      containerProps={{
        className: 'fx-1 fx fx-column gap-1',
        style: {
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
        }
      }}
    >
      <div
        className="fx-1 fx fx-column gap-1 p-1"
        style={{ flexDirection: 'column-reverse' }}
      >
        {messages.map((item) => (
          <Message
            key={item.id}
            message={item}
          />
        ))}
      </div>
    </InfiniteScroll>
  )
}
