export enum ECampaignSubmissionOrder {
  NEWEST_FIRST= 'NEWEST_FIRST',
  LATEST_FIRST= 'LATEST_FIRST',
  LIKED_FIRST= 'LIKED_FIRST'
}

export enum EViewCampaignOptions {
  VVC= 'VVC',
  SEXE= 'SEXE'
}

export enum ECampaignStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNVERIFIED ='UNVERIFIED'
}

export enum ESalaryRate {
  PER_HOUR = 'perHour',
  PER_MONTH = 'perMonth',
  PER_YEAR = 'perYear'
}

export enum ESalaryRange {
  RANGE = 'range',
  CUSTOM = 'custom',
  EXTRA_AMOUNT = 'extraAmount'
}

export enum EJobDescriptionType {
  UPLOAD_ATTACHMENT = 'uploadAttachment',
  MANUALLY = 'manually'
}
