import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { PeopleApi } from 'src/api'
import { BreadcrumbDivider, BreadcrumbHeading, Button, Dot, InfiniteScroll, InputDebounce, PageTitle, PageTitleSecondary, Spinner } from 'src/components'
import { EConnectionTabKey } from 'src/enums'
import { useBehaviorMapper, useDebouncedLoading, useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { IconSearch, IconThumbsUpDup } from 'src/icons'
import { IconEmailIllustration } from 'src/icons/icon-email-illustration'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { ConversationCard } from 'src/partials/card/converstation-card'
import { MessagesBox } from 'src/partials/message-box'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { MessengerService } from 'src/services/messenger.service'

export const Messages: FC = () => {
  const history = useHistory()
  const { lookupId } = useQueryParams()
  const [keyword, setKeyword] = useState<string>()

  const _loadMoreConversations = MessengerService._loadMoreConversations
  const conversations = useBehaviorMapper(MessengerService.conversations$)
  const pagination = useBehaviorMapper(_loadMoreConversations.pagination$)
  const conversation = useBehaviorMapper(MessengerService.conversation$)
  const countUnread = useBehaviorMapper(MessengerService.countUnRead$)
  const loading = useBehaviorMapper(_loadMoreConversations.loading$)

  const filteredConversations = useMemo(() => {
    if (!keyword) {
      return conversations
    }
    return conversations.filter(
      (item) => MessengerService.getConversationTitle(item)?.toLowerCase().includes(keyword.toLowerCase())
    )
  }, [conversations, keyword])

  useEffect(() => {
    MessengerService.pull()

    return () => {
      MessengerService.stopPull()
      MessengerService.setConversation()
      MessengerService._loadMoreConversations.reset()
    }
  }, [])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!lookupId) return

    from(PeopleApi.getConversation(lookupId as string))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        if (data.id) {
          _loadMoreConversations.addFirst([data])
          MessengerService.setConversation(data)
        }
      })
  }, [lookupId])

  const debounceLoading = useDebouncedLoading(!filteredConversations?.length && loading)

  const view = useMemo(() => {
    if (debounceLoading) {
      return (
        <div className="fx-1 fx fx-center">
          <Spinner/>
        </div>
      )
    }

    if (!filteredConversations.length) {
      return (
        <div className="fx-1 fx-column fx-center gap-5">
          <IconEmailIllustration/>

          <div className="fs-24 fw-700 txt-center txt-black-01">No messages yet!</div>
          <div className="txt-center txt-black-02">
            Start a conversation with Talent you’ve mutually liked.
          </div>

          <Button className="gap-2 mt-6" onClick={() => history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab: EConnectionTabKey.MATCHES }))}>
            <IconThumbsUpDup size={20}/>
            View Matches
          </Button>
        </div>
      )
    }

    return (
      filteredConversations.map((item) => (
        <ConversationCard
          key={item.id + (item.updatedAt || '')}
          conversation={item}
        />
      ))
    )
  }, [debounceLoading, filteredConversations, history])

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <div className="fx fx-ai-center gap-3">
            <BreadcrumbHeading>Talent</BreadcrumbHeading>
            <BreadcrumbDivider/>
            <BreadcrumbHeading active>Messages</BreadcrumbHeading>
          </div>
          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search messages"
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      <section className="fx-1 fx gap-8">
        <div className={clsx('fx-1 fx fx-column gap-5 p-8', conversation ? 'w-50' : 'w-full')}>
          <div className="fx fx-ai-center gap-2">
            <PageTitle>Messages</PageTitle>
            <PageTitleSecondary>({pagination.total})</PageTitleSecondary>
            {!!countUnread.countUnread && (
              <span className="fx fx-ai-center gap-2 ml-2">
                <Dot size={8} color="#FB5230"/>
                <span className="fs-14 lh-21 fw-700 txt-red-01">{countUnread.countUnread} unread</span>
              </span>
            )}
          </div>

          <InfiniteScroll
            className="fx-1 fx fx-wrap-wrap gap-4 py-4"
            loader={null}
            scrollThreshold={0.95}
            dataLength={conversations.length}
            hasMore={_loadMoreConversations.hasMore}
            next={() => _loadMoreConversations.loadMore({ limit: 10 })}
          >
            {view}
          </InfiniteScroll>
        </div>

        {conversation?.id && (
          <div className="w-50">
            <MessagesBox removeConversation={(id: number) => MessengerService._loadMoreConversations.removeOneItem(id)}/>
          </div>
        )}
      </section>
    </>
  )
}
