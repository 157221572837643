import { FC, useMemo } from 'react'
import {
  Button,
  Divider,
  Dot,
  MotivatorBadge,
  Tooltip
} from 'src/components'
import { EMotivator } from 'src/enums'
import { IconBookmark, IconExplanation, IconFile } from 'src/icons'
import { Img } from 'src/images'
import { ICampaignModel } from 'src/interfaces'
import { CampaignUtils, getFullName } from 'src/utils'

interface IProps {
  campaign: Partial<ICampaignModel>
  motivators: EMotivator[]
  isApplied?: boolean
  isHeart?: boolean
  loading?: boolean
  handleApply?: () => void
  handleHeart?: () => void
}

export const CampaignInfo: FC<IProps> = ({
  campaign,
  motivators,
  isApplied,
  isHeart,
  loading,
  handleApply,
  handleHeart
}) => {
  const parsedJob = useMemo(() => CampaignUtils.parse(campaign), [campaign])
  const video = campaign.uploadVideo ?? campaign.author?.pfv

  return (
    <div className={`fx-1 gap-8 ${video ? 'fx-column' : 'fx-row'}`}>
      <div>
        <div className="fx fx-column mb-6">
          <div className="fs-14 fw-500 lh-16 txt-black-01 mb-1">Hiring</div>
          <div className="fw-600 fs-24 lh-28 txt-black-01 mb-2">{campaign.jobTitle}</div>
          <div className="fx fx-wrap-wrap gap-1 row-gap-2">
            {motivators.map((motivator) => (
              <MotivatorBadge key={motivator} small value={motivator}/>
            ))}
          </div>
        </div>

        <div className="fx fx-column gap-2 mb-8">
          <div className="fx fx-ai-center fx-wrap-wrap gap-2">
            {campaign.logoUrl && (
              <Img
                src={campaign.logoUrl}
                width={17}
                height={17}
                alt="logo"
                className="round-10 bg-neutral-20 mr-2"
                style={{ objectFit: 'contain' }}
              />
            )}

            {campaign.companyName && <div className="fw-600 fs-12 txt-black-01">{campaign.companyName}</div>}
          </div>

          {
            parsedJob.salary && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">
                {parsedJob.salary}
                {parsedJob.salaryRate}
              </div>
            )
          }

          <div className="fx fx-ai-center fx-wrap-wrap gap-2">
            {campaign.location && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">{campaign.location + ' -'}</div>
            )}

            {parsedJob.locationType && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">{parsedJob.locationType}</div>
            )}
          </div>
        </div>

        <div className="fx gap-4">
          {isApplied
            ? (
              <Button variant="positive" className="pointer-none">Applied</Button>
            )
            : (
              <Button
                variant={campaign?.inviteApply ? 'label' : 'primary'}
                style={{ width: '154px' }}
                // onClick={handleApply}
              >
                Apply
              </Button>
            )}

          <Button
            variant="icon"
            active={isHeart}
            disabled={loading}
            // onClick={() => mustAuthenticated({ kind: 'save', actionMethod: handleHeart })}
          >
            <IconBookmark color="currentColor"/>
          </Button>
        </div>

        {campaign?.inviteApply && (
          <div className="mt-2 fx fx-ai-center gap-2">
            <Dot color="#FB5230"/>
            <span className="fs-12 lh-18 txt-black-01">{getFullName(campaign.author)} invited you to apply!</span>
          </div>
        )}
      </div>

      <Divider color="#E2E2E2" className={video ? 'txt-grey-02' : 'bg-grey-02'}/>

      <div className="fx fx-column gap-5">
        <div className="fs-16 fw-600 lh-20 txt-black-01 fx fx-ai-center gap-1">
          <span>Job Summary</span>
          <Tooltip placement="bottom" title="Powered by KNOWME&trade; Insights">
            <IconExplanation/>
          </Tooltip>
        </div>
        <div className="fs-12 lh-18 txt-black-01">{campaign.jobSummary}</div>

        {campaign.jdFileUrl && (
          <div
            className="fx gap-2 px-4 py-3 round-3 w-fit"
            style={{ border: '1px solid #D3D3D3' }}
          >
            <IconFile size={16}/>
            <span className="fs-12 fw-500 lh-16 txt-grey-01">
              {campaign.uploadedJdFile?.filename || campaign.jdFileUrl?.split('/').pop()}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
