import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconExplanation = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.25 2.125C14.5994 2.125 18.125 5.65142 18.125 10C18.125 14.3486 14.5994 17.875 10.25 17.875C5.90064 17.875 2.375 14.3486 2.375 10C2.375 5.65142 5.90064 2.125 10.25 2.125ZM10.25 3.43776C6.63144 3.43776 3.68776 6.38144 3.68776 10C3.68776 13.6186 6.63144 16.5622 10.25 16.5622C13.8686 16.5622 16.8122 13.6186 16.8122 10C16.8122 6.38144 13.8686 3.43776 10.25 3.43776Z" fill="#0C0C0C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2499 7.15027L9.5863 8.95114C9.52049 9.12972 9.37972 9.27049 9.20114 9.3363L7.40027 9.9999L9.20114 10.6635C9.37972 10.7293 9.52049 10.8701 9.5863 11.0487L10.2499 12.8495L10.9135 11.0487C10.9793 10.8701 11.1201 10.7293 11.2987 10.6635L13.0995 9.9999L11.2987 9.3363C11.1201 9.27049 10.9793 9.12972 10.9135 8.95114L10.2499 7.15027ZM9.67033 5.49061C9.84037 5.37212 10.0426 5.30859 10.2499 5.30859C10.4572 5.30859 10.6594 5.37212 10.8295 5.49061C10.9995 5.6091 11.1291 5.77686 11.2009 5.97129L12.0296 8.22019L14.2782 9.04877C14.4726 9.12053 14.6407 9.25029 14.7592 9.42033C14.8777 9.59037 14.9412 9.79264 14.9412 9.9999C14.9412 10.2072 14.8777 10.4094 14.7592 10.5795C14.6407 10.7495 14.4729 10.8791 14.2785 10.9509L12.0296 11.7796L11.201 14.0282C11.1293 14.2226 10.9995 14.3907 10.8295 14.5092C10.6594 14.6277 10.4572 14.6912 10.2499 14.6912C10.0426 14.6912 9.84037 14.6277 9.67033 14.5092C9.5003 14.3907 9.37066 14.2229 9.29889 14.0285L8.47019 11.7796L6.22163 10.951C6.0272 10.8793 5.8591 10.7495 5.74061 10.5795C5.62212 10.4094 5.55859 10.2072 5.55859 9.9999C5.55859 9.79265 5.62212 9.59037 5.74061 9.42033C5.8591 9.25029 6.02686 9.12066 6.22129 9.04889L8.47019 8.22019L9.29877 5.97163C9.37053 5.7772 9.50029 5.6091 9.67033 5.49061Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
})
