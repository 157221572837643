import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { DashboardApi } from 'src/api/dashboard'
import { BreadcrumbDivider, BreadcrumbHeading, InputDebounce, PageTitle, PageTitleSecondary } from 'src/components'
import { TabsNav } from 'src/components/tab-nav'
import { EConnectionTabKey } from 'src/enums'
import { useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { IconSearch } from 'src/icons'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { LoadingModule } from 'src/store/modules/loading.module'
import { LikedMyVibe } from '../liked-my-vibe'
import { Matches } from '../matches'
import { PeopleILiked } from '../people-i-liked'

export const Connections: FC = () => {
  const history = useHistory()
  const { tab } = useQueryParams()

  const [tabDefault, setTabDefault] = useState(EConnectionTabKey.MATCHES)
  const [counts, setCounts] = useState<Awaited<ReturnType<typeof DashboardApi.dashboard>>['data']>()
  const [keyword, setKeyword] = useState<string>()

  useUnsubscribeEffect((unsubscribe$) => {
    LoadingModule.toggle(true)
    from(DashboardApi.dashboard())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(({ data }) => setCounts(data))
  }, [])

  useEffect(() => {
    if ([EConnectionTabKey.MATCHES, EConnectionTabKey.LIKED_MY_VIBE, EConnectionTabKey.PEOPLE_I_LIKED].includes(tab as EConnectionTabKey)) {
      setTabDefault(tab as EConnectionTabKey)
    } else {
      history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab: EConnectionTabKey.MATCHES }))
    }
  }, [history, tab])

  const countsTotal = useMemo(() => (counts?.countMatches || 0) + (counts?.countLikedMe || 0) + (counts?.countILiked || 0), [counts])

  const items = useMemo(() => {
    return [
      {
        key: EConnectionTabKey.MATCHES,
        label: `Matches (${counts?.countMatches || 0})`,
        children: <Matches keyword={keyword}/>
      },
      {
        key: EConnectionTabKey.LIKED_MY_VIBE,
        label: `Liked My Vibe (${counts?.countLikedMe || 0})`,
        children: <LikedMyVibe keyword={keyword}/>
      },
      {
        key: EConnectionTabKey.PEOPLE_I_LIKED,
        label: `People I Liked (${counts?.countILiked || 0})`,
        children: <PeopleILiked keyword={keyword}/>
      }
    ]
  }, [counts, keyword])

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <div className="fx fx-ai-center gap-3">
            <BreadcrumbHeading>Talent</BreadcrumbHeading>
            <BreadcrumbDivider/>
            <BreadcrumbHeading active>Connections</BreadcrumbHeading>
          </div>
          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search applicants…"
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      <section className="fx-1 fx fx-column gap-5 p-8">
        <div className="fx fx-ai-center gap-2">
          <PageTitle>Connections</PageTitle>
          <PageTitleSecondary>({countsTotal || 0})</PageTitleSecondary>
        </div>

        <TabsNav
          className="fx-1 fx-column gap-8"
          defaultActiveKey={tabDefault}
          items={items}
          onChange={(tab) => history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab }))}
        />
      </section>
    </>
  )
}
