import { BehaviorSubject, filter } from 'rxjs'
import { AuthApi } from 'src/api'
import { IUserModel, IUserStatsModel } from 'src/interfaces'
import { CampaignMutationService, EventService, NotificationService } from 'src/services'
import { CreateJobTourService } from 'src/services/tour/create-job-tour.service'
import { GuestViewJobTourService } from 'src/services/tour/guest-view-job-tour.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { AuthUtils, getAvatar, getFirstCompany, getFullName } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'

/**
 * @deprecated
 */
const undefinedToEmptyString = (value?: string | null) => value ?? ''

export const AuthModule = new (
  class {
    private readonly _isAuthenticated$ = new BehaviorSubject<boolean | null>(null)
    get isAuthenticated$(): WithOutNextComplete<typeof this._isAuthenticated$> {
      return this._isAuthenticated$
    }

    private readonly _profile$ = new BehaviorSubject<IUserModel>({} as IUserModel)
    get profile$(): WithOutNextComplete<typeof this._profile$> {
      return this._profile$
    }

    private readonly _verifiedCompany$ = new BehaviorSubject(false)
    get verifiedCompany$(): WithOutNextComplete<typeof this._verifiedCompany$> {
      return this._verifiedCompany$
    }

    /**
     * @deprecated
     */
    private readonly _formattedProfile$ = new BehaviorSubject<{
      lookupId?: string
      email?: string
      firstName?: string
      lastName?: string
      fullName?: string
      companyName?: string
      companyUrls?: string[]
      bio?: string
      avatar?: string
      companyLogo?: string
      video?: string
    }>({})

    get formattedProfile$(): WithOutNextComplete<typeof this._formattedProfile$> {
      return this._formattedProfile$
    }

    /**
     * @deprecated
     */
    private readonly _countCredits$ = new BehaviorSubject(0)
    get countCredits$(): WithOutNextComplete<typeof this._countCredits$> {
      return this._countCredits$
    }

    constructor() {
      this._profile$
        .pipe(filter((profile) => !profile.id))
        .subscribe(() => {
          EventService.unbind()
          NotificationService.unbind()
        })

      this._profile$
        .pipe(filter((profile) => !!profile.id))
        .subscribe((profile) => {
          !this._isAuthenticated$.value && this._isAuthenticated$.next(true)
          if (profile?.email) {
            EventService.bind(profile?.email)
          }
          if (profile?.id) {
            NotificationService.bind(profile?.id)
          }
        })

      this._profile$.subscribe((profile) => {
        const isVerified = getFirstCompany(profile)?.CompanyUser?.isVerified || false
        isVerified !== this._verifiedCompany$.value && this._verifiedCompany$.next(isVerified)

        this._formattedProfile$.next({
          lookupId: undefinedToEmptyString(profile.lookupId),
          email: undefinedToEmptyString(profile.email),
          firstName: undefinedToEmptyString(profile.firstName),
          lastName: undefinedToEmptyString(profile.lastName),
          fullName: getFullName(profile),
          companyName: undefinedToEmptyString(profile.companies?.[0]?.name),
          companyUrls: profile.companies?.[0]?.urls || [],
          bio: undefinedToEmptyString(profile.bio),
          avatar: undefinedToEmptyString(getAvatar(profile)),
          companyLogo: undefinedToEmptyString(profile.companies?.[0]?.logo?.url),
          video: undefinedToEmptyString(profile.pfv?.urlVideoSource)
        })
      })
    }

    setStats(val: IUserStatsModel) {
      this._profile$.next({
        ...this._profile$.value,
        stats: {
          ...this._profile$.value.stats,
          ...val
        }
      })
    }

    setCountCredit(value: number) {
      this._countCredits$.next(value)
    }

    guide(value: Partial<IUserModel['guide']>) {
      this._profile$.next({
        ...this._profile$.value,
        guide: { ...this._profile$.value.guide, ...value }
      })
    }

    updateProfile(value: Partial<IUserModel>) {
      this._profile$.next({ ...this._profile$.value, ...value })
    }

    authenticated(user: IUserModel) {
      this._profile$.next(user)
    }

    unauthorized() {
      this._isAuthenticated$.value !== false && this._isAuthenticated$.next(false)
      this._profile$.next({} as IUserModel)
    }

    signOut() {
      return AuthApi.logout().finally(() => {
        this.unauthorized()
        AuthUtils.clear()
        PopupTourService.hide()
        CreateJobTourService.stopTour()
        ShareJobTourService.stopTour()
        GuestViewJobTourService.stopTour()
        CampaignMutationService.reset()
        sessionStorage.clear()
      })
    }
  }
)()
